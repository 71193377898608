import React, { useState, useEffect } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../controls/Controls";
import {seperateDateValues} from '../../services/employeeService';
import DatePicker from '../controls/DatePicker2';

export default function MonitoringToolFields(props) {

    const [monitoringTool, setMonitoringTool] = useState(props.fieldValues);
    const [enteredValues, setEnteredValues] = useState({});

    const handleInputChange = e => {

        const { name, value } = e.target

        setEnteredValues({
            ...enteredValues,
            [name]: value
        })

        //THIS NEEDS TO NOT WORK LIKE THIS. We are returning the whole object which it shouldnt - workaround
        setMonitoringTool({
            ...monitoringTool,
            [name]: value
        })
    }

    useEffect(() => {
        props.onCompleteFormSection(enteredValues)
    }, [enteredValues])


    const onDateChange = (passedDate) => {

        const formatDate = seperateDateValues(passedDate);

          
        setEnteredValues({
            ...enteredValues,
            'Date Taken' : `${formatDate.day}/${formatDate.month}/${formatDate.year}`
        })
 
        setMonitoringTool({
         ...monitoringTool,
         'Exit date' : `${formatDate.day}/${formatDate.month}/${formatDate.year}`
         })
       
     }
    


    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
            <Controls.Input
                label="Q1) How would you score your overall health?"
                name="Q1) Score your health"
                value={monitoringTool['Q1) Score your health']}
                type="number"
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
            />
            <Controls.Input
                label="Q2) How well are you sleeping?"
                name="Q2) How well sleeping"
                value={monitoringTool['Q2) How well sleeping']}
                type="number"
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
            />
            <Controls.Input
                label="Q3) How good is your appetite?"
                name="Q3) Emotional needs met"
                value={monitoringTool['Q3) Emotional needs met']}
                type="number"
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
            />
            <Controls.Input
                label="Q4) Do you feel alone or isolated?"
                name="Q4) Relaxed most of time"
                value={monitoringTool['Q4) Relaxed most of time']}
                type="number"
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
            />
            <Controls.Input
                label="Q5) You are able to discuss this situation with family?"
                name="Q5) Family aware"
                value={monitoringTool['Q5) Family aware']}
                type="number"
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
            />
            <Controls.Input
                label="Q6) Do you feel stressed or anxious?"
                name="Q6) Supported by family"
                value={monitoringTool['Q6) Supported by family']}
                type="number"
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
            />
            <Controls.Input
                label="Q7) Do you have skills to cope with stress and anxiety?"
                name="Q7) Housing Situation"
                value={monitoringTool['Q7) Housing Situation']}
                type="number"
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
            />
            <Controls.Input
                label="Q8) Knowledge of drugs or alcohol use?"
                name="Q8) Coping financially"
                value={monitoringTool['Q8) Coping financially']}
                type="number"
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
            />
            <Controls.Input
                label="Q9) Aware of other agencies that can help?"
                name="Q9) Aware legal rights"
                value={monitoringTool['Q9) Aware legal rights']}
                type="number"
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
            />

             <DatePicker label='Date Taken' onDateChange={onDateChange} />
  
        </Grid>
        <Grid item xs={6}>

        </Grid>
    </Grid>
    )
}
