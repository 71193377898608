import React, { useState, useEffect } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";

export default function DetailsandRelationships(props) {

    const [formData, setFormValues] = useState(props.fieldValues);
    const [thisSectionFormData, setThisSectionFormData] = useState([]);
    const {formOptions} = props;

    useEffect(() => {
        
        setThisSectionFormData({
            "Title" : formData['Title'],
            'First Name' : formData['First Name'],
            'Surname' : formData['Surname'],
            'Age' : formData['Age'],
            'Carers Relationship to User' : formData['Carers Relationship to User'],
            'Cared-for name' : formData['Cared-for name'],
            'Ethnicity' : formData['Ethnicity']
            
        })

    }, [])

    const handleInputChange = e => {

        const { name, value } = e.target

        setThisSectionFormData({
            ...thisSectionFormData,
            [name]: value
        })
        setFormValues({
            ...formData,
            [name]: value
        })
    }

    useEffect(() => {
        props.onCompleteFormSection(thisSectionFormData)
        
    }, [thisSectionFormData])

   


    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Controls.Input
                        label="Title"
                        name="Title"
                        value={formData['Title']}
                        onChange={handleInputChange}
                    />
                    <Controls.Input
                        label="First Name"
                        name="First Name"
                        value={formData['First Name']}
                        onChange={handleInputChange}
                    />
                    <Controls.Input
                        label="Surname"
                        name="Surname"
                        value={formData['Surname']}
                        onChange={handleInputChange}
                    />
                    <Controls.Select
                            label="Age"
                            name="Age"
                            value={formData['Age']}
                            onChange={handleInputChange}
                            options={['undefined','Under 18', '10-30', '31-50', '50-70', '70+']}
                        />
                </Grid>
                <Grid item xs={6}>
                            <Controls.Select
                            label="Carers Relationship to User"
                            name="Carers Relationship to User"
                            value={formData['Carers Relationship to User']}
                            onChange={handleInputChange}
                            options={formOptions['single']['CarersRelationship']}
                            />
                            <Controls.Input
                                label="Cared-for name"
                                name="Cared-for name"
                                value={formData['Cared-for name']}
                                onChange={handleInputChange}
                            />
            
                            <Controls.Select
                                label="Ethnicity"
                                name="Ethnicity"
                                value={formData['Ethnicity']}
                                onChange={handleInputChange}
                                options={formOptions['single']['ethnicity']}
                            />
                 
                </Grid>
            </Grid>
    )
}
