import React, { useState, useEffect } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../controls/Controls";

export default function HubAndTreatment(props) {

    const [formData, setFormValues] = useState(props.fieldValues);
    const [thisSectionFormData, setThisSectionFormData] = useState([]);
    const {formOptions} = props;

    useEffect(() => {
        
        setThisSectionFormData({
            "Local Hub" : formData['Local Hub'],
            "Location" : formData['Location'],
            "Organisation" : formData['Organisation'],
            "User In Treatment?" : formData['User In Treatment?'],
            "Safeguarding Required" : formData['Safeguarding Required'],
            "Related to" : formData['Related to'],
            "Recovery Worker" : formData['Recovery Worker']
        })

    }, [])
    
    const handleInputChange = e => {

        const { name, value } = e.target

        setThisSectionFormData({
            ...thisSectionFormData,
            [name]: value
        })
        setFormValues({
            ...formData,
            [name]: value
        })
    }

    useEffect(() => {
        props.onCompleteFormSection(thisSectionFormData)
      
    }, [thisSectionFormData])

   


    const onChangeAutoSelect = (data) => {
        console.log('AUTOSELECT', data)
        setThisSectionFormData({
            ...thisSectionFormData,
            'Related to': data
        })
        setFormValues({
            ...formData,
            'Related to': data
        })
       
    }

    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Controls.Select
                        label="Local Hub"
                        name="Local Hub"
                        value={formData['Local Hub']}
                        onChange={handleInputChange}
                        options={formOptions['single']['hubName']}
                    />
                    <Controls.Select
                        label="Location"
                        name="Location"
                        value={formData['Location']}
                        onChange={handleInputChange}
                        options={formOptions['single']['location']}
                    />
                    <Controls.Input
                        label="Organisation"
                        name="Organisation"
                        value={formData['Organisation']}
                        onChange={handleInputChange}
                    />
                    <Controls.Select
                            label="FSW"
                            name="Carer Code"
                            value={formData['Carer Code']}
                            onChange={handleInputChange}
                            options={formOptions['single']['First Name']}
                        />
                </Grid>
                <Grid item xs={6}>
                        <Controls.Select
                            label="User In Treatment?"
                            name="User In Treatment?"
                            value={formData['User In Treatment?']}
                            onChange={handleInputChange}
                            options={['In Treatment', 'Not in treatment', 'Unknown', 'Deceased']}
                        />
                        
                        <Controls.SelectFieldObj
                        label="Safeguarding Required"
                        name="Safeguarding Required"
                        value={formData['Safeguarding Required']}
                        onChange={handleInputChange}
                        options={[{'value': 'TRUE', 'text': 'Yes'}, {'value': 'FALSE', 'text': 'No'}]}
                        />
                        
                        <Controls.AutoSelect3
                            defaultValues={formData['Related to']} 
                            defaultVals={formData}
                            options={formOptions['single']['drugs']} 
                            onChange={onChangeAutoSelect}
                            label="Related to Substance"
                            placeholder="Substance"
                        />
                    
                        <Controls.Input
                        label="Recovery Worker"
                        name="Recovery Worker"
                        value={formData['Recovery Worker']}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>
    )
}
