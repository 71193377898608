import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton } from '@material-ui/core'
import Controls from "./controls/Controls";
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import { makeStyles } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
}))

export default function PasswordProtector(props) {

    const { confirmDialog, setConfirmDialog } = props;
    const [password, setPassword] = useState('');
    const classes = useStyles()
    const navigate = useNavigate();

    const handleInputChange = (e) => {

        const { name, value } = e.target
   
        setPassword(value);

    }

    const handleClick = () => {

         if(password === '2205'){

            setConfirmDialog({ ...confirmDialog, isOpen: false });

        }

    }

    const handleNoClick = () => {

        navigate('/contacts');

   }

    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton disableRipple className='test'>
                    <NotListedLocationIcon  />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
            </DialogContent>
            <DialogActions className='flex-wrap'>
                
                <Controls.Input
                    label="Password"
                    name="Password"
                    value={password}
                    onChange={handleInputChange}
                    className="mr-2"
                    />

                    <Controls.Button
                    text="Cancel"
                    onClick={handleNoClick}
                    color="error" />
                <Controls.Button
                    text="Confirm"
                    onClick={handleClick}
                    color="primary" />
                    
            </DialogActions>
        </Dialog>
    )
}
