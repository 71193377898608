import React, {useState, useEffect} from 'react'
import PageHeader from "../../components/PageHeader";
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import { Paper, Toolbar } from '@material-ui/core';
import { useParams, useLocation  } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Controls from "../../components/controls/Controls";
import {Form} from '../../components/useForm';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ConfirmDialog from '../../components/ConfirmDialog';
import ConfirmDateialog from '../../components/ConfirmDateDialog';
import Notification from '../../components/Notification';
import * as Service from "../../services/employeeService";
import { Link } from "react-router-dom";
import TableWithControls from '../../components/Table/TableWithControls';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useSearchParams } from "react-router-dom";
import DatePicker from '../../components/controls/DatePicker2';
import {seperateDateValues} from '../../services/employeeService';
import {useAuth} from '../../context/auth-context'; 

import * as Axios from 'axios';
import { te } from 'date-fns/locale';
const devAPI = Service.devAPI;

const useStyles = makeStyles(theme => ({
   

}))


const initalformData = {
    ...Service.initalformContacts
}


const columns = [
    ...Service.tableHeaderSupportLogs
]



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


export default function Contact() {

    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();
    const contactId = params.id;
    const [contact, setContact] = useState(initalformData);
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [tableData, setTableData] = useState({});
    const [monitoringToolTableData, setMonitoringToolTableData] = useState({});
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const [exitDateChanged, setExitDateChanged] = useState(false);
    const [errors, setErrors] = useState({});
    const [confirmDateDialog, setConfirmDateDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const [showExitCalender, setShowExitCalender] = useState(false);

    const {currentUser, logout} = useAuth();
    const [formOptions, setFormOptions] = useState();
    let [searchParams, setSearchParams] = useSearchParams();
    const paramType = searchParams.get("type");
    const paramSubmit = searchParams.get("submit");

    const location = useLocation();

    useEffect(() => {
        
        console.log(location.pathname);

        Service.getContactsFormFieldsFromDB().then((data) => {

            setFormOptions(data);
          
        });

        if(paramSubmit === 'success'){
            
            setNotify({
                isOpen: true,
                message: `${paramType}ed Successfully`,
                type: 'success'
            })

        }else if(paramSubmit === 'success'){

            setNotify({
                isOpen: true,
                message: `${paramType} Failed`,
                type: 'error'
            })

        }

        if(location.pathname === '/contact/new'){

            setIsEdit(false);

            const formatDate = seperateDateValues(new Date());

            console.log('date', formatDate)
 
            setContact({
            ...contact,
            'Date Logged' : `${formatDate.day}/${formatDate.month}/${formatDate.year}`,
            'UniqueIdentifier' : Service.generateUID()
            })

        }else{

            setIsEdit(true);

            getContactData();

        }
        

    }, [])


    const handleChange = (event, newValue) => {

        setValue(newValue);

    };

    const fullName = `${contact['Title']} ${contact['First Name']} ${contact['Surname']}`

    const safeguardingRequiredVal = contact['Safeguarding Required'];

    const getContactData = () => {

        setIsLoading(true);

        Service.getTokenForAPI().then(idToken => {
            Axios.get(`${devAPI}/api/get/contact/${contactId}`, {headers: {authorization: idToken}}).then((data) => {

                let contactData = data.data;

                console.log('CONTACT DATA RECIEVED', data.data)

                setContact(contactData);
                setIsLoading(false);
    
            }).catch((error) => {
                console.log("Error getting Contact Data:", error);
            });
        })

    }




    const validate = () => {

        let temp = {};

        temp['Contact Preference'] = contact['Contact Preference'] ? '' : 'This field is required';
        temp['Carers Relationship to User'] = contact['Carers Relationship to User'] ? '' : 'This field is required';
        temp['Ethnicity'] = contact['Ethnicity'] ? '' : 'This field is required';
        temp['Age'] = contact['Age'] ? '' : 'This field is required';
        temp['Local Hub'] = contact['Local Hub'] ? '' : 'This field is required';
        temp['User In Treatment?'] = contact['User In Treatment?'] ? '' : 'This field is required';
        temp['Location'] = contact['Location'] ? '' : 'This field is required';
        temp['Safeguarding Required'] = contact['Safeguarding Required'] ? '' : 'This field is required';
        temp['Carer Code'] = contact['Carer Code'] ? '' : 'This field is required';
        temp['User In Treatment?'] = contact['User In Treatment?'] ? '' : 'This field is required';
        temp['Logged By'] = contact['Logged By'] ? '' : 'This field is required';
        temp['Hear about PSL'] = contact['Hear about PSL'] ? '' : 'This field is required';
        temp['GDPR'] = contact['GDPR'] ? '' : 'This field is required';
        temp['ConsentToShare'] = contact['ConsentToShare'] ? '' : 'This field is required';
        
        setErrors({
            ...temp
        })

        return Object.values(temp).every(x => x == '');

    }



    const handleSubmit = e => {
        console.log('user has hit the submit button to create a new contact')
        if(isEdit){

            Service.updateContactOnDB(contact).then((data) => {

                if(data === null){
                    throw 'Error!';
                  } else{
                    navigate(`/contacts`);
                    setContact(initalformData);
                    setNotify({
                        isOpen: true,
                        message: 'Updated Contact',
                        type: 'success'
                    })
                  }
               
    
        
            }).catch((err) => {
    
    
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false
                })
    
                setConfirmDateDialog({
                    ...confirmDialog,
                    isOpen: false
                })
    
                setNotify({
                    isOpen: true,
                    message: 'Updated Failed',
                    type: 'error'
                })
                
            });


        }else{

            if(validate()){

                let contactObj = contact;

                contactObj['UniqueIdentifier'] = Service.generateUID();
                
                console.log('creating new contact', contactObj)

                Service.createContactOnDB(contactObj).then((data) => {

                    console.log('created new contact', data);
                    if(data === null){
                        throw 'Error!';
                      } else{
                        navigate(`/contacts`);
                        setContact(initalformData);
                        setNotify({
                            isOpen: true,
                            message: 'Created Contact',
                            type: 'success'
                        })
                      }
                    
    
               }).catch(err => {

                   setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false
                    })
        
                    setConfirmDateDialog({
                        ...confirmDialog,
                        isOpen: false
                    })
               });


            }else{

                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false
                })
    
                setConfirmDateDialog({
                    ...confirmDialog,
                    isOpen: false
                })

            }

        }

    }


    const handleInputChange = e => {

        const { name, value } = e.target

        console.log('date-logged', contact['Date Logged'])

        if(isEdit){

            setContact({
                ...contact,
                [name]: value
            })

        }else{
            const formatDate = seperateDateValues(new Date());

            setContact({
                ...contact,
                [name]: value,
                'Date Logged' : `${formatDate.day}/${formatDate.month}/${formatDate.year}`
            })

        }
    }

    const onChangeAutoSelect = (data) => {

        console.log('AUTOSELECT', data)

        setContact({
            ...contact,
            'Related to': data
        })
       
    }

    const handleExitUserBtn = () => {
        if(currentUser.email === 'rhona.hawkins@pslcharity.org.uk' || currentUser.email === 'admin@pslcharity.org.uk'){
            setShowExitCalender(!showExitCalender);
        }else{
            alert('Please speak to supervisor to exit client')
        }
    }

    const onDateChange = (date) => {

        const formatDate = seperateDateValues(date);
 
         setContact({
         ...contact,
         'Exit date' : `${formatDate.day}/${formatDate.month}/${formatDate.year}`
         })
     
 
         setExitDateChanged(true);
       
     }

     useEffect(() => {
        
        if( showExitCalender === true ){

            let currentDate = new Date();

            const formatDate = seperateDateValues(currentDate);
     
             setContact({
             ...contact,
             'Exit date' : `${formatDate.day}/${formatDate.month}/${formatDate.year}`
             })
         

        }else{
     
             setContact({
             ...contact,
             'Exit date' : ``
             })
         

        }

    }, [showExitCalender])



    return (
        <>      
                <Box sx={{ mb: 4}}>
                    <Controls.Button
                        text="Back"
                        component={Link} 
                        to={`/contacts`}
                    />
                </Box>        
            <PageHeader
                title='Contact'
                icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
            />

            
            {
                    isEdit &&
           
                     <Paper>
                        <Grid container spacing={2} sx={{ p: 4, mb: 4 }}> 
                            <Grid item xs={4}>
                                <Box>
                                    <Typography variant="h6" gutterBottom component="div" className="text-color-primary-dark">
                                        Name: {fullName}
                                    </Typography>
                                    <Typography variant="p" gutterBottom component="div" className="text-color-primary">
                                        ID: {contactId}
                                    </Typography>
                                    {
                                        safeguardingRequiredVal === 'TRUE' &&
                                        <div style={{ 
                                            padding: '12px', 
                                            background: 'red', 
                                            width: '230px', 
                                            marginTop: '20px', 
                                            color: 'white', 
                                            textTransform: 'uppercase', 
                                            fontWeight: 'bold' 
                                          }}>
                                            Safeguarding Required
                                          </div> 
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={8}>

                            </Grid>
                        </Grid>
                    </Paper>                            
            }

            {
                   ! isEdit &&
           
                     <Paper>
                        <Grid container spacing={2} sx={{ p: 4, mb: 4 }}> 
                            <Grid item xs={4}>
                                <Box>
                                    <Typography variant="h5" gutterBottom component="div" className="text-color-primary-dark">
                                        Create Contact
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={8}>

                            </Grid>
                        </Grid>
                    </Paper>                            
            }

            <Paper>

                <Box sx={{ width: '100%', p: 4 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="Tabs">
                        <Tab label="Contact Details" {...a11yProps(0)} />
                        <Tab label="Support Log" {...a11yProps(1)} disabled={!isEdit} />
                        <Tab label="Monitoring Tool" {...a11yProps(2)} disabled={!isEdit} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {isLoading == false && formOptions &&
                            <Form onSubmit={handleSubmit}>
                                <Grid container>
                                    <Typography variant="h5" sx={{ my: 4 }} component={'span'} >Address and Contact Details</Typography>
                                    

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <Controls.Input
                                                label="House Name or Number"
                                                name="House Name or Number"
                                                value={contact['House Name or Number']}
                                                onChange={handleInputChange}

                                            />
                                            <Controls.Input
                                                label="Street Name"
                                                name="Street Name"
                                                value={contact['Street Name']}
                                                onChange={handleInputChange}
                                            />
                                            <Controls.Input
                                                label="Town/City"
                                                name="Town/City"
                                                value={contact['Town/City']}
                                                onChange={handleInputChange}
                                            />
                                            <Controls.Input
                                                label="Email Address"
                                                name="Email Address"
                                                value={contact['Email Address']}
                                                onChange={handleInputChange}
                                            />
                                            <Controls.Input
                                                    label="Telephone (Mobile)"
                                                    name="Telephone (Mobile)"
                                                    value={contact['Telephone (Mobile)']}
                                                    onChange={handleInputChange}
                                                />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controls.Input
                                                    label="Postcode"
                                                    name="Postcode"
                                                    value={contact['Postcode']}
                                                    onChange={handleInputChange}
                                                />
                                                <Controls.Input
                                                    label="Telephone (Home)"
                                                    name="Telephone (Home)"
                                                    value={contact['Telephone (Home)']}
                                                    onChange={handleInputChange}
                                                />
                                                <Controls.Input
                                                    label="Telephone (Work)"
                                                    name="Telephone (Work)"
                                                    value={contact['Telephone (Work)']}
                                                    onChange={handleInputChange}
                                                />
                                                <Controls.Select
                                                    label="Contact Preference"
                                                    name="Contact Preference"
                                                    value={contact['Contact Preference']}
                                                    onChange={handleInputChange}
                                                    options={formOptions['single']['ContactPreference']}
                                                    error={errors['Contact Preference']}
                                                />
                                        </Grid>
                                    </Grid>




                                    <Divider sx={{ my: 6 }}  />


                                     <Typography variant="h5" sx={{ my: 4 }} component={'span'} >Details and Relationship</Typography>

                                     <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>



                                    <Grid item xs={6}>
                                        <Controls.Input
                                            label="Title"
                                            name="Title"
                                            value={contact['Title']}
                                            onChange={handleInputChange}
                                        />
                                        <Controls.Input
                                            label="First Name"
                                            name="First Name"
                                            value={contact['First Name']}
                                            onChange={handleInputChange}
                                        />
                                        <Controls.Input
                                            label="Surname"
                                            name="Surname"
                                            value={contact['Surname']}
                                            onChange={handleInputChange}
                                        />
                                        <Controls.Select
                                                label="Age"
                                                name="Age"
                                                value={contact['Age']}
                                                onChange={handleInputChange}
                                                options={['undefined','Under 18', '10-30', '31-50', '50-70', '70+']}
                                                error={errors['Age']}
                                            />
                                    </Grid>
                                    <Grid item xs={6}>
                                                <Controls.Select
                                                label="Carers Relationship to User"
                                                name="Carers Relationship to User"
                                                value={contact['Carers Relationship to User']}
                                                onChange={handleInputChange}
                                                options={formOptions['single']['CarersRelationship']}
                                                error={errors['Carers Relationship to User']}
                                                />
                                                <Controls.Input
                                                    label="Cared-for name"
                                                    name="Cared-for name"
                                                    value={contact['Cared-for name']}
                                                    onChange={handleInputChange}
                                                />
                                
                                                <Controls.Select
                                                    label="Ethnicity"
                                                    name="Ethnicity"
                                                    value={contact['Ethnicity']}
                                                    onChange={handleInputChange}
                                                    options={formOptions['single']['ethnicity']}
                                                    error={errors['Ethnicity']}
                                                />
                                    
                                    </Grid>
                                </Grid>




                                    <Divider sx={{ my: 6 }}  />

                                     <Typography variant="h5" sx={{ my: 4 }} component={'span'} >Emergency Details</Typography>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <Controls.Input
                                                label="Emergency Name"
                                                name="Emergency Name"
                                                value={contact['Emergency Name']}
                                                onChange={handleInputChange}
                                            />
                                            <Controls.Input
                                                label="Emergency Number"
                                                name="Emergency Nunber"
                                                value={contact['Emergency Nunber']}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controls.Input
                                                label="Emergency known"
                                                name="Emergency known"
                                                value={contact['Emergency known']}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    </Grid>




                                    <Divider sx={{ my: 6 }}  />
                                    <Typography variant="h5" sx={{ my: 4 }} component={'span'} >Hub and Treatment</Typography>
                                    


                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <Controls.Select
                                                label="Local Hub"
                                                name="Local Hub"
                                                value={contact['Local Hub']}
                                                onChange={handleInputChange}
                                                options={formOptions['single']['hubName']}
                                                error={errors['Local Hub']}
                                            />
                                            <Controls.Select
                                                label="Location"
                                                name="Location"
                                                value={contact['Location']}
                                                onChange={handleInputChange}
                                                options={formOptions['single']['location']}
                                                error={errors['Location']}
                                            />
                                            <Controls.Input
                                                label="Organisation"
                                                name="Organisation"
                                                value={contact['Organisation']}
                                                onChange={handleInputChange}
                                            />
                                            <Controls.Select
                                                    label="FSW"
                                                    name="Carer Code"
                                                    value={contact['Carer Code']}
                                                    onChange={handleInputChange}
                                                    options={formOptions['single']['First Name']}
                                                    error={errors['Carer Code']}
                                                />
                                        </Grid>
                                        <Grid item xs={6}>
                                                <Controls.Select
                                                    label="User In Treatment?"
                                                    name="User In Treatment?"
                                                    value={contact['User In Treatment?']}
                                                    onChange={handleInputChange}
                                                    options={['In Treatment', 'Not in treatment', 'Unknown', 'Deceased']}
                                                    error={errors['User In Treatment?']}
                                                />
                                                
                                                <Controls.SelectFieldObj
                                                label="Safeguarding Required"
                                                name="Safeguarding Required"
                                                value={contact['Safeguarding Required']}
                                                onChange={handleInputChange}
                                                options={[{'value': 'N/A', 'text': 'N/A'},{'value': 'TRUE', 'text': 'Yes'}, {'value': 'FALSE', 'text': 'No'}]}
                                                error={errors['Safeguarding Required']}
                                                />
                                                
                                                <Controls.AutoSelect3
                                                    defaultValues={contact['Related to']} 
                                                    defaultVals={contact}
                                                    options={formOptions['single']['drugs']} 
                                                    onChange={onChangeAutoSelect}
                                                    label="Related to Substance"
                                                    placeholder="Substance"
                                                />
                                            
                                                <Controls.Input
                                                label="Recovery Worker"
                                                name="Recovery Worker"
                                                value={contact['Recovery Worker']}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    </Grid>



                                    <Divider sx={{ my: 6 }}  />
                                    <Typography variant="h5" sx={{ my: 4 }} component={'span'} >Logged By</Typography>



                                    {/* <LoggedBy fieldValues={contact} formOptions={formOptions} onCompleteFormSection={saveFormSectionData} setExitDateChanged={setExitDateChanged} />  */}

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <Controls.SelectObj
                                                label="Logged By - FSW"
                                                name="Logged By"
                                                value={contact['Logged By']}
                                                onChange={handleInputChange}
                                                options={formOptions['object']}
                                                error={errors['Logged By']}
                                            />
                                            <Controls.Input
                                                label="Date Logged"
                                                name="Date Logged"
                                                value={contact['Date Logged']}
                                                onChange={handleInputChange}
                                                disabled
                                            />
                                            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={6}>
                                                    <Controls.Input
                                                        label="Exit date"
                                                        name="Exit date"
                                                        value={contact['Exit date']}
                                                        onChange={handleInputChange}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className="exit-btn">
                                                    <Controls.Button
                                                        text="Exit User"
                                                        color="error"
                                                        onClick={() => {handleExitUserBtn()}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sx={{ mb: 4 }} >
                                                    {
                                                        showExitCalender && 

                                                        <DatePicker label='Exit Date' onDateChange={onDateChange} />

                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controls.Select
                                                label="Hear about PSL"
                                                name="Hear about PSL"
                                                value={contact['Hear about PSL']}
                                                onChange={handleInputChange}
                                                options={formOptions['single']['hearAboutUs']}
                                                error={errors['Hear about PSL']}
                                            />
                                            <Controls.SelectFieldObj
                                                label="GDPR"
                                                name="GDPR"
                                                value={contact['GDPR']}
                                                onChange={handleInputChange}
                                                options={[{'value': 'N/A', 'text': 'N/A'},{'value': 'TRUE', 'text': 'Yes'}, {'value': 'FALSE', 'text': 'No'}]}
                                                error={errors['GDPR']}
                                            />
                                            <Controls.SelectFieldObj
                                                label="Consent To Share"
                                                name="ConsentToShare"
                                                value={contact['ConsentToShare']}
                                                onChange={handleInputChange}
                                                options={[{'value': 'N/A', 'text': 'N/A'},{'value': 'TRUE', 'text': 'Yes'}, {'value': 'FALSE', 'text': 'No'}]}
                                                error={errors['ConsentToShare']}
                                            />
                                        </Grid>
                                    </Grid>


                                    {
                                        isEdit &&
                                        <Controls.Button
                                        text="Update"
                                        color="warning"
                                        sx={{ pt: 1 }}
                                        onClick={() => {
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: 'Are you sure to update this record?',
                                                subTitle: "You can't undo this operation",
                                                onConfirm: () => { handleSubmit() }
                                            })
                                        }}
                                     />
                                    }
                                    {
                                        isEdit == false &&
                                        <Controls.Button
                                        text="Create"
                                        color="success"
                                        sx={{ pt: 1 }}
                                        onClick={() => {
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: 'Are you sure to create this record?',
                                                subTitle: "",
                                                onConfirm: () => { handleSubmit() }
                                            })
                                        }}
                                     />
                                    }
                    
                                    
                                </Grid>
                            </Form>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TableWithControls 
                            tableData={tableData} 
                            tableName='supportLog'
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <TableWithControls 
                            tableData={monitoringToolTableData} 
                            tableName='monitoringTool'
                        />
                    </TabPanel>
                </Box>
            </Paper>
    
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}

