import React, {useState, useEffect, useCallback, useRef} from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';


import { toPng } from 'html-to-image';

import DatePicker from '../controls/DatePicker2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import * as Service from "../../services/employeeService";
import { makeStyles } from '@material-ui/styles';
import Grid from '@mui/material/Grid';
import Controls from '../controls/Controls';
import Alert from '@mui/material/Alert';


export default function SimpleBarChart(props) {

  const [ chartData, setChartData ] = useState([]); 
  const [isLoading, setIsLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [currentDate, setCurrentDate] = useState({});
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [currentSelectOption, setCurrentSelectOption] = useState(props.defaultSelectOption);
  


  const {XAxisName, BarName, heading, location, selectOptionsKey, selectOptionsLabel, selectName} = props;

  useEffect(() => {

    setIsLoading(true);

    props.chartDataFunc(currentSelectOption, startDate, endDate).then((data) => {
              console.log('chart data returned', data);
              setChartData(data);
              setIsLoading(false);
              
        });

        if(selectName){

          props.selectOptions().then((data) => {

            selectOptionsKey === false ? setSelectOptions(data) : setSelectOptions(data['single'][selectOptionsKey]);
  
          });

        }

  }, [])


  useEffect(() => {

    setIsLoading(true);

    props.chartDataFunc(currentSelectOption, startDate, endDate).then((data) => {
     // console.log('DATA', data);
              setChartData(data);
              setIsLoading(false);
              
        });

  }, [startDate, endDate, currentSelectOption])


const onDateChangeStart = (date) => {

  setCurrentDate(date);

  const dateVals = Service.seperateDateValues(date);

  setStartDate(dateVals);

}

const onDateChangeEnd = (date) => {

  setCurrentDate(date);

  const dateVals = Service.seperateDateValues(date);

  setEndDate(dateVals);

}

const onSelectChange = (value) => {

  setCurrentSelectOption(value.target.value);

}


const ref = React.createRef(null);


const onButtonClick = useCallback(() => {
  if (ref.current === null) {
    return
  }

  toPng(ref.current, { cacheBust: true, })
    .then((dataUrl) => {
      const link = document.createElement('a')
      link.download = 'my-image-name.png'
      link.href = dataUrl
      link.click()
    })
    .catch((err) => {
      console.log(err)
    })
}, [ref])



  return (

    <Box>
          <Typography variant="h5" gutterBottom component="div" sx={{ mb: 4}}>
            {heading}
          </Typography>

        <Grid container spacing={2}>

        <Grid item xs={12}  sx={{ mb: 2}}>
          <Alert  severity="info">By default the charts are set to show from the beginning of time</Alert>
        </Grid>

        <Grid item xs={12}>

              <DatePicker label='Start Date'  onDateChange={onDateChangeStart} />

        </Grid>

        <Grid item xs={12}>

              <DatePicker label='End Date'  onDateChange={onDateChangeEnd} />

        </Grid>


        {
          selectName && (


          <Grid item xs={12} sx={{ mb: 2}}>
                        
            <Controls.Select
            label={selectOptionsLabel}
            name={selectName}
            value={currentSelectOption}
            onChange={onSelectChange}
            options={selectOptions}
            />

          </Grid>

          )
          
        }

        <Grid item xs={12}>
                      
          <Controls.Button onClick={onButtonClick} text="Export Chart" />
          
        </Grid>


        <Grid item xs={12}>

          



                { !isLoading &&

                <div class="bk-white"  ref={ref} >

                  <Box sx={{ width: '100%', height: '500px', pb: '10rem' }}  >

                        <ResponsiveContainer sx={{ width: '100%', height: '100%'}}>
                          <BarChart             
                                   
                            data={chartData}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey={XAxisName}  
                                  angle={45} 
                                textAnchor="start" 
                                tick={{fontSize: 11 }} 
                                minTickGap={-200} />
                            <YAxis  tick={{fontSize: 11 }} />
                            <Tooltip />
                            <Legend layout="horizontal" verticalAlign="top" align="center"/>
                            <Bar dataKey={BarName} fill="#407eff" isAnimationActive={false}>
                              <LabelList dataKey={BarName}  position="top" />
                            </Bar>
                          </BarChart>
                          </ResponsiveContainer>

                      </Box>

                </div>

                  }

          
          </Grid>
  


        </Grid>       
     </Box>
  );
}
