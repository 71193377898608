import React, { useState, useEffect } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../controls/Controls";


export default function EmergencyDetails(props) {

    const [formData, setFormValues] = useState(props.fieldValues);
    const [thisSectionFormData, setThisSectionFormData] = useState([]);
    const {formOptions} = props;


    useEffect(() => {
        
        setThisSectionFormData({
            "Emergency Name" : formData['Emergency Name'],
            'Emergency Nunber' : formData['Emergency Nunber'],
            'Emergency known' : formData['Emergency known'],
        })

    }, [])
    
    const handleInputChange = e => {

        const { name, value } = e.target

        setThisSectionFormData({
            ...thisSectionFormData,
            [name]: value
        })
        setFormValues({
            ...formData,
            [name]: value
        })
    }

    useEffect(() => {
        props.onCompleteFormSection(thisSectionFormData)
       
    }, [thisSectionFormData])

   


    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Controls.Input
                        label="Emergency Name"
                        name="Emergency Name"
                        value={formData['Emergency Name']}
                        onChange={handleInputChange}
                    />
                    <Controls.Input
                        label="Emergency Number"
                        name="Emergency Nunber"
                        value={formData['Emergency Nunber']}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                        label="Emergency known"
                        name="Emergency known"
                        value={formData['Emergency known']}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>
    )
}
