import React, {useState, useEffect} from 'react'
import {  Typography, Paper } from '@material-ui/core';
import Box from '@mui/material/Box';
import PageHeader from "../components/PageHeader";
import Grid from '@mui/material/Grid';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import {Form} from '../components/useForm';
import { useParams } from "react-router-dom";
import * as Service from "../services/employeeService";
import Controls from "../components/controls/Controls";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import * as Axios from 'axios';
import { useSearchParams } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import DatePicker from '../components/controls/DatePicker2';

import ConfirmDialog from '../components/ConfirmDialog';
import Notification from '../components/Notification';



const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}))

const initalformData = {
    ...Service.initalformLoggedSupport
}

export default function SupportLog() {

    const classes = useStyles();
    const params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const supportLogId = searchParams.get("supportLogId");
    const contactId = searchParams.get("contactId");
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState({});
    
    const [supportLog, setSupportLog] = useState(initalformData);
    const [formOptions, setFormOptions] = useState();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })


    const navigate = useNavigate();
    const isEdit = searchParams.get("edit") === 'true' ? true : false;

 ////////////////////////////////////////////////////////////////////////
const fetchSupportLogData = () => {

    setIsLoading(true);

    if(supportLogId){

        Service.getTokenForAPI().then(idToken => {
    
            Axios.get(`${Service.devAPI}/api/get/supportlog/${supportLogId}`, {headers: {authorization: idToken}}).then((data) => {

                setSupportLog(data.data[0]);
    
                console.log('Table Data has been fetched successfully', data.data[0]);
    
                setIsLoading(false);
    
    
            }).catch((error) => {

                console.log("Error getting Contact Data:", error);

                setIsLoading(false);

            });
        })

    }else{
        setIsLoading(false);
    }

}

 ////////////////////////////////////////////////////////////////////////

 ////////////////////////////////////////////////////////////////////////
 useEffect(() => {

    console.log('contactID', contactId);

    if(isEdit == false){

        let num = Service.generateUID();
        console.log('NEW-NUMBER', num);

        setSupportLog({
            ...supportLog,
            'Unique Identifier' : contactId,
            'SupportLogID' :  num
        })

    }

    fetchSupportLogData();

    Service.getSupportLogFormFieldsFromDB().then((data) => {

        setFormOptions(data);

    });

}, [])

 ////////////////////////////////////////////////////////////////////////

 const validate = () => {

    let temp = {};

    temp['Support Type'] = supportLog['Support Type'] ? '' : 'This field is required';
    temp['Attended'] = supportLog['Attended'] ? '' : 'This field is required';
    temp['Location'] = supportLog['Location'] ? '' : 'This field is required';
    temp['Carers that Attended'] = supportLog['Carers that Attended'] ? '' : 'This field is required';
    temp['Linked to Hub'] = supportLog['Linked to Hub'] ? '' : 'This field is required';
    temp['Signpost To'] = supportLog['Signpost To'] ? '' : 'This field is required';
    temp['Supported By'] = supportLog['Supported By'] ? '' : 'This field is required';
    temp['User In Treatment?'] = supportLog['User In Treatment?'] ? '' : 'This field is required';
    temp['Domestic Voilence'] = supportLog['Domestic Voilence'] ? '' : 'This field is required';
    temp['Mental Health'] = supportLog['Mental Health'] ? '' : 'This field is required';
    temp['Criminal Justice'] = supportLog['Criminal Justice'] ? '' : 'This field is required';
    temp['Safeguarding Required'] = supportLog['Safeguarding Required'] ? '' : 'This field is required';

    
    setErrors({
        ...temp
    })

    return Object.values(temp).every(x => x == '');

}

 ////////////////////////////////////////////////////////////////////////
const handleSubmit = () => {

    if(isEdit){
        
        Service.updateSupportLogOnDB(supportLog).then((data) => {
        
            
            if(data === null){
                throw 'Error!';
              } else{
                navigate(`/contact/${contactId}/?submit=success&type=Update`);
                setSupportLog(initalformData)
              }
           
            
    
        }).catch((error) => {

            
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })

        });

    }else{

        if(validate()){

            let supportLogObj = supportLog;

            supportLogObj['SupportLogID'] = Service.generateUID()

            Service.createSupportLogOnDB(supportLogObj).then((data) => {
                
              if(data === null){
                throw 'Error!';
              } else{
                navigate(`/contact/${contactId}/?submit=success&type=Submit`); 

              }
        
            }).catch((e) => {
                
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false
                })
        
            });

        }else{
    
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
    
        }

    }

}

 ////////////////////////////////////////////////////////////////////////

 const handleInputChange = e => {

    const { name, value } = e.target

    setSupportLog({
        ...supportLog,
        [name]: value,
        'Unique Identifier' : contactId,
    })

    console.log('SUPPORT-LOG', supportLog['Unique Identifier']);
}

useEffect(() => {

    let formattedDate = Service.seperateDateValues(new Date());

    setSupportLog({
        ...supportLog,
        'new_date': `${formattedDate.day}/${formattedDate.month}/${formattedDate.year}`
    })

}, [])

const onDateChangeStart = (date) => {
        
    let formattedDate = Service.seperateDateValues(date);

    setSupportLog({
        ...supportLog,
        'new_date': `${formattedDate.day}/${formattedDate.month}/${formattedDate.year}`
    })
  
  }



    return (

        <>
                <Box sx={{ mb: 2}}>
                    <Controls.Button
                        text="Back"
                        component={Link} 
                        to={`/contact/${contactId}`}
                    />
                </Box>
            <PageHeader
                title='Log Support'
                icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
            />
 
            {(formOptions && !isLoading) && 
                <Form>
                    <Paper >
                        <Grid container sx={{ p: 4, mb: 2}}>
                            <Typography variant="h5" sx={{ my: 4 }} component={'span'} >Support Details</Typography>
    


                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <DatePicker
                                                date={isEdit ? new Date(supportLog['new_date'].split('/')[2], supportLog['new_date'].split('/')[1] - 1, supportLog['new_date'].split('/')[0]) : new Date()}
                                                onDateChange={onDateChangeStart} 
                                                label='Support Date'
                                            />
                                            <Controls.Input
                                                label="Duration"
                                                name="Duration"
                                                value={supportLog['Duration']}
                                                onChange={handleInputChange}
                                            />
                                            <Controls.Select
                                                label="Support Type"
                                                name="Support Type"
                                                value={supportLog['Support Type']}
                                                onChange={handleInputChange}
                                                options={formOptions['single']['supportTypes']}
                                                error={errors['Support Type']}
                                            />
                                            <Controls.Select
                                                label="Attended"
                                                name="Attended"
                                                value={supportLog['Attended']}
                                                onChange={handleInputChange}
                                                options={['Yes', 'Cancelled', 'Did not attend']}
                                                error={errors['Attended']}
                                                />
                                        </Grid>
                                        <Grid item xs={6}>
                                                <Controls.Select
                                                    label="Location"
                                                    name="Location"
                                                    value={supportLog['Location']}
                                                    onChange={handleInputChange}
                                                    options={formOptions['single']['location']}
                                                    error={errors['Location']}
                                                />
                                                <Controls.Select
                                                label="Carers that Attended"
                                                name="Carers that Attended"
                                                value={supportLog['Carers that Attended']}
                                                onChange={handleInputChange}
                                                options={formOptions['single']['attended']}
                                                error={errors['Carers that Attended']}
                                                />
                                        </Grid>
                                        <Grid item xs={12}>
                                                <Controls.Input
                                                label="Notes"
                                                name="Notes"
                                                value={supportLog['Notes']}
                                                onChange={handleInputChange}
                                                multiline
                                                rows={5}
                                                />
                                        </Grid>
                                    </Grid>



                        </Grid>
                    </Paper>
                    <Paper >
                        <Grid container sx={{ p: 4, mb: 2 }}>
                        <Typography variant="h5" sx={{ my: 4 }} component={'span'} >External Support Details</Typography>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <Controls.Select
                                        label="Linked to Hub"
                                        name="Linked to Hub"
                                        value={supportLog['Linked to Hub']}
                                        onChange={handleInputChange}
                                        options={formOptions['single']['hubName']}
                                        error={errors['Linked to Hub']}
                                    />
                                    <Controls.Select
                                        label="Signpost To"
                                        name="Signpost To"
                                        value={supportLog['Signpost To']}
                                        onChange={handleInputChange}
                                        options={formOptions['single']['agency']}
                                        error={errors['Signpost To']}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controls.SelectObj
                                            label="Supported By"
                                            name="Supported By"
                                            value={supportLog['Supported By']}
                                            onChange={handleInputChange}
                                            options={formOptions['object']}
                                            error={errors['Supported By']}
                                        />
                                </Grid>
                            </Grid>


                        </Grid>
                    </Paper>
                    <Paper >
                        <Grid container sx={{ p: 4, mb: 2 }}>
                        <Typography variant="h5" sx={{ my: 4 }} component={'span'} >Treatment</Typography>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}> 
                                <Grid item xs={6}>
                                    <Controls.SelectFieldObj
                                        label="User In Treatment?"
                                        name="User In Treatment?"
                                        value={supportLog['User In Treatment?']}
                                        onChange={handleInputChange}
                                        options={[{'value': 'Yes', 'text': 'Yes'}, {'value': 'No', 'text': 'No'}, {'value': 'Unknown', 'text': 'Unknown'}, {'value': 'Deceased', 'text': 'Deceased'}]}
                                        error={errors['User In Treatment?']}
                                    />
                                    <Controls.SelectFieldObj
                                        label="domestic Abuse"
                                        name="Domestic Voilence"
                                        value={supportLog['Domestic Voilence']}
                                        onChange={handleInputChange}
                                        options={[{'value': 'N/A', 'text': 'N/A'},{'value': 'TRUE', 'text': 'Yes'}, {'value': 'FALSE', 'text': 'No'}]}
                                        error={errors['Domestic Voilence']}
                                    />
                                    <Controls.SelectFieldObj
                                        label="Mental Health"
                                        name="Mental Health"
                                        value={supportLog['Mental Health']}
                                        onChange={handleInputChange}
                                        options={[{'value': 'N/A', 'text': 'N/A'},{'value': 'TRUE', 'text': 'Yes'}, {'value': 'FALSE', 'text': 'No'}]}
                                        error={errors['Mental Health']}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controls.SelectFieldObj
                                        label="Criminal Justice"
                                        name="Criminal Justice"
                                        value={supportLog['Criminal Justice']}
                                        onChange={handleInputChange}
                                        options={[{'value': 'N/A', 'text': 'N/A'},{'value': 'TRUE', 'text': 'Yes'}, {'value': 'FALSE', 'text': 'No'}]}
                                        error={errors['Criminal Justice']}
                                    />
                                    <Controls.SelectFieldObj
                                        label="Safeguarding Required"
                                        name="Safeguarding Required"
                                        value={supportLog['Safeguarding Required']}
                                        onChange={handleInputChange}
                                        options={[{'value': 'N/A', 'text': 'N/A'},{'value': 'TRUE', 'text': 'Yes'}, {'value': 'FALSE', 'text': 'No'}]}
                                        error={errors['Safeguarding Required']}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                        <Controls.Button
                            text={isEdit === true ? 'Update' : 'Submit'}

                            onClick={() => {
                                setConfirmDialog({
                                    isOpen: true,
                                    title: 'Are you sure to update this record?',
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => { handleSubmit() }
                                })
                            }}
                        />
                    
                </Form>
            }
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}
