import * as Axios from 'axios';
//import { useNavigate } from 'react-router-dom';
import {firebase} from '../firebase';


export const devAPI = 'https://pslhub.uk';
//https://pslhub.uk http://localhost:3001

export function getTokenForAPI(){

  return firebase.auth().currentUser.getIdToken();

}



////////////////////////////////////////////////////////////////////////

export const tableHeaderContacts = [
    {field: 'id', headerName: 'Table ID', width: 50},
    {field: 'UniqueIdentifier', headerName: 'Unique Identifier', width: 150},
    {field: 'Title', headerName: 'Title', width: 170},
    {field: 'First Name', headerName: 'First Name', width: 170},
    {field: 'Surname', headerName: 'Surname', width: 170},
    {field: 'House Name or Number', headerName: 'House Name or Number', width: 200},
    {field: 'Street Name', headerName: 'Street Name', width: 150},
    {field: 'Town/City', headerName: 'Town/City', width: 150},
    {field: 'Postcode', headerName: 'Postcode', width: 150},
    {field: 'Age', headerName: 'Age', width: 150},
    {field: 'Email Address', headerName: 'Email Address', width: 200},
    {field: 'Telephone (Mobile)', headerName: 'Telephone (Mobile)', width: 200},
    {field: 'Telephone (Home)', headerName: 'Telephone (Home)', width: 200},
    {field: 'Telephone (Work)', headerName: 'Telephone (Work)', width: 200},
    {field: 'Contact Preference', headerName: 'Contact Preference', width: 150},
    {field: 'Carers Relationship to User', headerName: 'Carers Relationship to User', width: 250},
    {field: 'Cared-for name', headerName: 'Cared-for name', width: 200},
    {field: 'Last Used PSL', headerName: 'Last Used PSL', width: 150},
    {field: 'Related to', headerName: 'Related to', width: 150},
    {field: 'Recovery Worker', headerName: 'Recovery Worker', width: 170},
    {field: 'Safeguarding Required', headerName: 'Safeguarding Required', width: 200},
    {field: 'Local Hub', headerName: 'Local Hub', width: 150},
    {field: 'Logged By', headerName: 'Logged By', width: 150},
    {field: 'Location', headerName: 'Location', width: 150},
    {field: 'Carer Code', headerName: 'Carer Code', width: 150},
    {field: 'Date Logged', headerName: 'Date Logged', width: 150},
    {field: 'Hear about PSL', headerName: 'Hear about PSL', width: 150},
    {field: 'Organisation', headerName: 'Organisation', width: 150},
    {field: 'User In Treatment?', headerName: 'User In Treatment?', width: 150},
    {field: 'Add to Mail?', headerName: 'Add to Mail?', width: 150},
    {field: 'Mental Health?', headerName: 'Mental Health?', width: 150},
    {field: 'Criminal Justice?', headerName: 'Criminal Justice?', width: 150},
    {field: 'Domestic Voilence?', headerName: 'Domestic Voilence?', width: 150},
    {field: 'Ethnicity', headerName: 'Ethnicity', width: 150},
    {field: 'Ethnicity', headerName: 'Ethnicity', width: 150},
    {field: 'GDPR', headerName: 'GDPR', width: 150},
    {field: 'Emergency Name', headerName: 'Emergency Name', width: 150},
    {field: 'Emergency Nunber', headerName: 'Emergency Nunber', width: 150},
    {field: 'Emergency known', headerName: 'Emergency Known', width: 150},
    {field: 'ConsentToShare', headerName: 'Consent To Share', width: 150},
]

export const tableHeaderSupportGroupView = [
    {field: 'id', headerName: 'Table ID', width: 50},
    {field: 'UniqueIdentifier', headerName: 'Unique Identifier', width: 100},
    {field: 'Title', headerName: 'Title', width: 100},
    {field: 'First Name', headerName: 'First Name', width: 150},
    {field: 'Surname', headerName: 'Surname', width: 150},
]

export const tableHeaderSupportLogs = [
    {field: 'id', headerName: 'Table ID', width: 100},
    {field: 'SupportLogID', headerName: 'SupportLogID', width: 150},
    {field: 'Unique Identifier', headerName: 'Unique Identifier', width: 150},
    {field: 'new_date', headerName: 'Support Date', width: 150},
    {field: 'Support Type', headerName: 'Support Type', width: 150},
    {field: 'Carers that Attended', headerName: 'Carers that Attended', width: 250},
    {field: 'Attended', headerName: 'Attended', width: 150},
    {field: 'Notes', headerName: 'Notes', width: 150},
    {field: 'Location', headerName: 'Location', width: 200},
    {field: 'Supported By', headerName: 'Supported By', width: 200},
    {field: 'Duration', headerName: 'Duration', width: 200},
    {field: 'Signpost From', headerName: 'Signpost From', width: 150},
    {field: 'Linked to Hub', headerName: 'Linked to Hub', width: 150},
    {field: 'Signpost To', headerName: 'Signpost To', width: 150},
    {field: 'Signposted By', headerName: 'Signposted By', width: 150},
    {field: 'Time of Call', headerName: 'Time of Call', width: 150},
    {field: 'Domestic Voilence', headerName: 'Domestic Voilence', width: 150},
    {field: 'Mental Health', headerName: 'Mental Health', width: 200},
    {field: 'Criminal Justice', headerName: 'Criminal Justice', width: 200},
    {field: 'User In Treatment?', headerName: 'User In Treatment?', width: 200},
    {field: 'Safeguarding Required', headerName: 'Safeguarding Required', width: 200},
    {field: 'Next Support Date', headerName: 'Next Support Date', width: 200},
    {field: 'Support Group', headerName: 'Support Group', width: 200},
    {field: 'Work Shop', headerName: 'Work Shop', width: 200},
    {field: 'Support Date', headerName: 'Old Support Date (Test)', width: 200}
]

export const tableHeaderMonitoringTool = [
    {field: 'id', headerName: 'Table ID', width: 100},
    {field: 'MonitorID', headerName: 'MonitorID', width: 150},
    {field: 'UniqueIdentifier', headerName: 'UniqueIdentifier', width: 150},
    {field: 'Q1) Score your health', headerName: 'Q1) How would you score your overall health?', width: 250},
    {field: 'Q2) How well sleeping', headerName: 'Q2) How well are you sleeping?', width: 250},
    {field: 'Q3) Emotional needs met', headerName: 'Q3) How good is your appetite?', width: 250},
    {field: 'Q4) Relaxed most of time', headerName: 'Q4) Do you feel alone or isolated?', width: 250},
    {field: 'Q5) Family aware', headerName: 'Q5) You are able to discuss this situation with family?', width: 250},
    {field: 'Q6) Supported by family', headerName: 'Q6) Do you feel stressed or anxious?', width: 250},
    {field: 'Q7) Housing Situation', headerName: 'Q7) Do you have skills to cope with stress and anxiety?', width: 250},
    {field: 'Q8) Coping financially', headerName: 'Q8) Knowledge of drugs or alcohol use?', width: 250},
    {field: 'Q9) Aware legal rights', headerName: 'Q9) Aware of other agencies that can help?', width: 250},
    {field: 'Date Taken', headerName: 'Date Taken', width: 200}
]

export const tableHeaderSupportGroup = [
    {field: 'id', headerName: 'Table ID', width: 100},
    {field: 'SupportGroupID', headerName: 'SupportGroupID', width: 200},
    {field: 'Support Group Name', headerName: 'Support Group Name', width: 200},
    {field: 'support_group_name', headerName: 'Support Group Name (New)', width: 250},
    {field: 'Held On', headerName: 'Held On', width: 150},
    {field: 'Support Details', headerName: 'Support Details', width: 200},
    {field: 'Hub', headerName: 'Hub', width: 150},
    {field: 'Time-Out?', headerName: 'Time-Out?', width: 150},
    {field: 'SupportGroupGeneral', headerName: 'SupportGroupGeneral', width: 200},

]

export const tableHeaderLinkedToHub = [
  {field: 'Linked to Hub', headerName: 'Linked to Hub', width: 100},
  {field: 'Telephone (Incoming)', headerName: 'Telephone (Incoming)', width: 200},
  {field: 'Written Correspondence', headerName: 'Written Correspondence', width: 200},
  {field: '1-2-1 (Community Venue)', headerName: '1-2-1 (Community Venue)', width: 200},
  {field: '1-2-1 (Office)', headerName: '1-2-1 (Office)', width: 200},
  {field: 'Dedicated Telephone Support', headerName: 'Dedicated Telephone Support', width: 200},
  {field: 'Telephone (Outgoing)', headerName: 'Telephone (Outgoing)', width: 200},
  {field: 'Email Correspondence', headerName: 'Email Correspondence', width: 200},
  {field: '1-2-1 (Home Visit)', headerName: '1-2-1 (Home Visit)', width: 200},
  {field: '1-2-1 (Hampshire Hub)', headerName: '1-2-1 (Hampshire Hub)', width: 200},
  {field: 'Support Group', headerName: 'Support Group', width: 200},
  {field: 'Workshop Group', headerName: 'Workshop Group', width: 200},
  {field: 'Text', headerName: 'Text', width: 200},
  {field: '1-2-1 (Zoom)', headerName: '1-2-1 (Zoom)', width: 200},
  {field: '1-2-1 (Whats App)', headerName: '1-2-1 (Whats App)', width: 200},
  {field: 'Group Zoom', headerName: 'Group Zoom', width: 200},
  {field: 'Group Whats App', headerName: 'Group Whats App', width: 200},
]



////////////////////////////////////////////////////////////////


export const initalformContacts = {

    "UniqueIdentifier": generateUID(),
    "Title": "",
    "First Name": "",
    "Surname": "",
    "House Name or Number": "",
    "Street Name": "",
    "Town/City": "",
    "Postcode": "",
    "Age": "",
    "Email Address": "",
    "Telephone (Mobile)": "",
    "Telephone (Home)": "",
    "Telephone (Work)": "",
    "Contact Preference": "",
    "Carers Relationship to User": "",
    "Cared-for name" : "",
    "Users Relationship to Carer": "",
    "Last Used PSL": "",
    "Related to": [],
    "Recovery Worker": "",
    "Safeguarding Required": "",
    "Local Hub": "",
    "Logged By": "",
    "Location": "",
    "Carer Code": "",
    "Date Logged": "",
    "Hear about PSL": "",
    "Organisation": "",
    "User In Treatment?": "",
    "Add to Mail?": "",
    "Mental Health?": "",
    "Criminal Justice?": "",
    "Domestic Voilence?": "",
    "Ethnicity": "",
    "Exit date": "",
    "GDPR": "",
    "Emergency Name": "",
    "Emergency Nunber": "",
    "Emergency known": "",
    "ConsentToShare": ""
}

export const initalformLoggedSupport = {
    "SupportLogID": generateUID(),
    "Unique Identifier": "",
    "Support Date": "", 
    "Support Type": "",	
    "Carers that Attended": "",	
    "Attended": "",	
    "Notes": "",	
    "Location": "",	
    "Supported By": "",	
    "Duration": "",	
    "Signpost From": "",	
    "Linked to Hub": "",	
    "Signpost To": "",	
    "Signposted By": "",	
    "Time of Call": "",	
    "Domestic Voilence": "",	
    "Mental Health": "",	
    "Criminal Justice": "",	
    "User In Treatment?": "",	
    "Safeguarding Required": "",	
    "Next Support Date": "",	
    "Support Group": "",	
    "Work Shop": "",
    "new_date": ""
    
}


export const initalformMonitoringTool = {

    "MonitorID" : generateUID(),	
    "UniqueIdentifier" : '',	
    "Q1) Score your health" : '',
    "Q2) How well sleeping" : '',
    "Q3) Emotional needs met" : '',
    "Q4) Relaxed most of time" : '',
    "Q5) Family aware" : '',	
    "Q6) Supported by family" : '',
    "Q7) Housing Situation" : '',	
    "Q8) Coping financially" : '',	
    "Q9) Aware legal rights" : '',	
    "Q10) Rate their health" : '',	
    "Q11) How involved family is care" : '',	
    "Q12) What their housing situ" : '',
    "Q13) How are they financially" : '',	
    "Q14) Legal concerns" : '',	
    "Q15) Recovery process inproved" : '',	
    "Date Taken" : ''
    
}

export const initalSupportGroup = {

    "SupportGroupID" : generateUID(),	
    "Support Group Name" : '',	
    "Held On" : '',
    "Support Details" : '',
    "Hub" : '',
    "Time-Out?" : '',
    "SupportGroupGeneral" : '',
    "support_group_name": '',



    //Why was this not added to this initalaly? where does this value go after being captured. its in office form?
    //these are the select options so the values must go above?
    "Attended": '',
    "FSW": ''
    
}

 ////////////////////////////////////////////////////////////////////////


 ////////////////////////////////////////////////////////////////////////
export function generateUID(){
    return Math.floor(Math.pow(10, 10-1) + Math.random() * 9 * Math.pow(10, 10-1));
  }

 ////////////////////////////////////////////////////////////////////////



 ////////////////////////////////////////////////////////////////////////
  // CREATE // SINGLE CONTACT
  export function submitContactToDB(contactObj){
    Axios.post(`${devAPI}/api/post`, contactObj).then(() => {
        alert('successful insert');
    })
  }

 ////////////////////////////////////////////////////////////////////////

 ////////////////////////////////////////////////////////////////////////
  // GET // ALL CONTACT
  export async function getContactsFromDB(){

    let i = 1; 

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        console.log('THE ID TOKEN:', idToken);
        console.log('the url address get requests:', `${devAPI}/api/get`);
        var responseData = await Axios.get(`${devAPI}/api/get`, {headers: {authorization: idToken}})
        console.log('the response data:', responseData.data);
        responseData.data.forEach(contact => {
            contact.id = i;
            i++;
        })

        return responseData.data

      } catch(error) {

        return null;
      }

  }

 ////////////////////////////////////////////////////////////////////////

 ////////////////////////////////////////////////////////////////////////
  export async function getSupportGroups(){

    try {
        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/supportgroup`, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

 ////////////////////////////////////////////////////////////////////////

 ////////////////////////////////////////////////////////////////////////
  export function matchKeyObj(obj, apiName){

    let subForm;

    if(apiName === 'supportlog'){

      subForm = initalformLoggedSupport;

      for (const [key, value] of Object.entries(obj)) {
          subForm[key] = value;
        }

    }else if(apiName === 'monitoringtool'){

      subForm = initalformMonitoringTool;

      for (const [key, value] of Object.entries(obj)) {
          subForm[key] = value;
        }
    }

    return subForm;
}

 ////////////////////////////////////////////////////////////////////////

 ////////////////////////////////////////////////////////////////////////
  export async function submitToDB(objData, apiName){

    let formVals = Object.values(objData);
    let formKeys = Object.keys(objData);

    formVals.forEach((val, index) => {
        if(val === ''){
            formVals[index] = '""';
        }else{
            formVals[index] = `"${val}"`;
        }
    })  
    formKeys.forEach((val, index) => {
        formKeys[index] = "`" + val + "`"
    })  

    formKeys = formKeys.toString();

    try {
        const idToken = await firebase.auth().currentUser.getIdToken()

        const responseData = await Axios.post(`${devAPI}/api/create/${apiName}`, 
          {formVals: formVals, formKeys: formKeys}, 
          {headers: {authorization: idToken}})

          if(responseData.data.error == true){
        
            alert( responseData.data.message)
            throw responseData.data.message
          }

        return responseData.data

      } catch(error) {

        return null;
      }

  }

 ////////////////////////////////////////////////////////////////////////


  ////////////////////////////////////////////////////////////////////////
  export async function submitObjToDB(objData, apiName){


    try {
        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/create/${apiName}`, {objData: objData}, {headers: {authorization: idToken}})

        if(responseData.data.error == true){
        
          alert( responseData.data.message)
          throw responseData.data.message
        }

        return responseData.data

      } catch(error) {

        return null;
      }

  }

 ////////////////////////////////////////////////////////////////////////



 ////////////////////////////////////////////////////////////////////////

  export async function updateRowOnDB(passedObj){

     let rowData = [];

     for (const [key, value] of Object.entries(passedObj.log)) {

         let keyString = "`" + key + "`";

         rowData.push(`${keyString} = "${value}"`);
       }
     
     let formatedRowData = rowData.toString();

    try {
        const idToken = await firebase.auth().currentUser.getIdToken()

        if(passedObj.apiName === 'contact'){

          var responseData = await Axios.put(`${devAPI}/api/update/${passedObj.apiName}`, {rowData: formatedRowData, contactID: passedObj.contactID}, {headers: {authorization: idToken}})

        }else if(passedObj.apiName === 'monitoringtool'){

          var responseData = await Axios.put(`${devAPI}/api/update/${passedObj.apiName}`, {rowData: formatedRowData, monitorID: passedObj.id}, {headers: {authorization: idToken}})

        }else{
          var responseData = await Axios.put(`${devAPI}/api/update/${passedObj.apiName}`, {rowData: formatedRowData, supportLogId: passedObj.id}, {headers: {authorization: idToken}})
        }

        if(responseData.data.error == true){
        
          alert( responseData.data.message)
          throw responseData.data.message
        }

        return responseData.data

      } catch(error) {

        return null;
      }

  }


  export async function createContactOnDB(formData){
    
   try {
       const idToken = await firebase.auth().currentUser.getIdToken()

       var responseData = await Axios.post(`${devAPI}/api/create/contact`, {data: formData}, {headers: {authorization: idToken}})

       if(responseData.data.error == true){
        
        alert( responseData.data.message)
        throw responseData.data.message
      }

       return responseData.data

     } catch(error) {

       return null;
     }

 }

  export async function updateContactOnDB(contactData){

   try {
       const idToken = await firebase.auth().currentUser.getIdToken()

       var responseData = await Axios.put(`${devAPI}/api/update/contact`, {data: contactData, id: contactData.UniqueIdentifier}, {headers: {authorization: idToken}})

       if(responseData.data.error == true){
        
        alert( responseData.data.message)
        throw responseData.data.message
      }

       return responseData.data

     } catch(error) {

       return null;
     }

 }

 export async function createSupportLogOnDB(formData){
    
  try {
      const idToken = await firebase.auth().currentUser.getIdToken()

      var responseData = await Axios.post(`${devAPI}/api/create/supportLog`, {data: formData}, {headers: {authorization: idToken}})


      if(responseData.data.error == true){
        
        alert( responseData.data.message)
        throw responseData.data.message
      }


      return responseData.data

    } catch(error) {
   
      return null;
    }

}

export async function updateSupportLogOnDB(formData){

  try {
      const idToken = await firebase.auth().currentUser.getIdToken()

      var responseData = await Axios.put(`${devAPI}/api/update/supportLog`, {data: formData}, {headers: {authorization: idToken}})

      if(responseData.data.error == true){
        
        alert( responseData.data.message)
        throw responseData.data.message
      }

      return responseData.data

    } catch(error) {

      return null;
    }

}

 ////////////////////////////////////////////////////////////////////////



 export async function deleteOnDB(tableName, selectedRows, tableData ){


  let tableItemsArr = [];

  let apiName = '';

  if(tableName === 'supportLog'){

    apiName = 'supportlog';

    for (const uid of selectedRows){

        let tableItem = tableData.find(tableItem => tableItem.id === uid);

        tableItemsArr.push(tableItem['SupportLogID']);
    }


  }else if(tableName === 'monitoringTool'){

    apiName = 'monitoringtool';

    for (const uid of selectedRows){

        let tableItem = tableData.find(tableItem => tableItem.id === uid);

        tableItemsArr.push(tableItem['MonitorID']);
    }

  }else if(tableName === 'contacts'){

    apiName = 'contacts';

    for (const uid of selectedRows){

        let tableItem = tableData.find(tableItem => tableItem.id === uid);

        tableItemsArr.push(tableItem['UniqueIdentifier']);
    }

  }else if(tableName === 'supportGroup'){

    apiName = 'supportgroup';

    for (const uid of selectedRows){

        let tableItem = tableData.find(tableItem => tableItem.id === uid);

        tableItemsArr.push(tableItem['SupportGroupID']);
    }

  }

  try {

      const idToken = await firebase.auth().currentUser.getIdToken()
      var responseData = await Axios.post(`${devAPI}/api/delete/${apiName}`, {data: tableItemsArr}, {headers: {authorization: idToken}})

      if(responseData.data.error == true){
        
        alert( responseData.data.message)
        throw responseData.data.message
      }

      return responseData.data

    } catch(error) {

      return null;
    }

}


 ////////////////////////////////////////////////////////////////////////



  ////////////////////////////////////////////////////////////////////////

  export async function updateSupportGroupToDB(passedObj){



    const idToken = await firebase.auth().currentUser.getIdToken()
   return Axios.put(`${devAPI}/api/update/supportgroup`, {object: passedObj}, {headers: {authorization: idToken}});

 }

////////////////////////////////////////////////////////////////////////



 ////////////////////////////////////////////////////////////////////////
export const getTableColumns = (tableName) => {

    if(tableName === 'supportLog'){

        return [
            ...tableHeaderSupportLogs
        ]

    }else if(tableName === 'monitoringTool'){
        
        return [
            ...tableHeaderMonitoringTool
        ]

    }else if(tableName === 'supportGroup'){
        
        return [
            ...tableHeaderSupportGroup
        ]

    }else if(tableName === 'linkedToHub'){
        
      return [
          ...tableHeaderLinkedToHub
      ]

  }
}

 ////////////////////////////////////////////////////////////////////////

export const getAddNewUrl = (tableName, contactId) => {

    
    if(tableName === 'supportLog'){

        return `/loggedsupport?edit=false&contactId=${contactId}`;

   }else if(tableName === 'monitoringTool'){
        
        return `/monitoringtool?edit=false&contactId=${contactId}`;
        
    }else if(tableName === 'supportGroup'){
        
        return `/supportgroupview?edit=false&SupportGroupID=${contactId}`;
        
    }
}

 ////////////////////////////////////////////////////////////////////////


 ////////////////////////////////////////////////////////////////////////
export const getEditUrl = (tableName, tableItem) => {

    if(tableName === 'supportLog'){

        return `/loggedsupport?edit=true&contactId=${tableItem['Unique Identifier']}&supportLogId=${tableItem['SupportLogID']}`;

    }else if(tableName === 'monitoringTool'){
        
        return `/monitoringtool?edit=true&contactId=${tableItem['UniqueIdentifier']}&MonitorID=${tableItem['MonitorID']}`;

    }else if(tableName === 'supportGroup'){
        
        return `/supportgroupview?edit=true&SupportGroupID=${tableItem['SupportGroupID']}`;

    }
}

 ////////////////////////////////////////////////////////////////////////




 ////////////////////////////////////////////////////////////////////////
export const onView = (tableName, selectedRows) => {

    if(tableName === 'supportLog'){

        return 'supportlogs/' + selectedRows[0];

    }else if(tableName === ''){
            
          return 'monitoringtool/' + selectedRows[0];
            
    }else if(tableName === 'supportGroup'){
            
        return 'supportgroup/' + selectedRows[0];
          
  }
}

 ////////////////////////////////////////////////////////////////////////


 ////////////////////////////////////////////////////////////////////////
export const getAPIName = (tableName) => {
    
    if(tableName === 'supportLog'){

        return 'supportlogs';

    }else if(tableName === 'monitoringTool'){
            
          return 'monitoringtools';
            
    }else if(tableName === 'supportGroup'){
            
        return 'supportgroup';
          
  }
}

 ////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////GET SELECT/////////////////////////////

  export async function getContactsFormFieldsFromDB(){

    try {
        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.get(`${devAPI}/api/get/contactformfields`, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getLocationSelectOptionsFromDB(){

    try {
        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.get(`${devAPI}/api/get/location`, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getIssues(){

    try {

        var responseData = ['Safeguarding Required', 'User In Treatment?', 'Mental Health?', 'Criminal Justice?', 'Domestic Voilence?']

        return responseData

      } catch(error) {

        return null;
      }

  }

  export async function getDrugsSelectOptionsFromDB(){

    try {
        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.get(`${devAPI}/api/get/drug`, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getHearAboutUsSelectOptionsFromDB(){

    try {
        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.get(`${devAPI}/api/get/hearaboutusoptions`, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getSupporGroupFormFieldsFromDB(){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.get(`${devAPI}/api/get/supportgroupformfields`, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }


  export async function getSupportLogFormFieldsFromDB(){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.get(`${devAPI}/api/get/supportLogformfields`, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getHubsFromDB(){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.get(`${devAPI}/api/get/hubs`, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getCallersRelationshipToUserOptions(){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.get(`${devAPI}/api/get/carersRelationships`, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  


  export async function getAllSupportLogsDB(){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.get(`${devAPI}/api/get/supportlogs`, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }



  //////////////////////////////////////////////////////GET REPORTS/////////////////////////////

  export async function checkManagementAccess(email){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.get(`${devAPI}/api/get/checkmanagementaccess/${email}`, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export const combineMonthAndDate = (data) => {

    data.forEach(val => {
      val['yearAndMonth'] = `${val.Month} / ${val.Year}`
    })

    return data;
  }

  export const seperateDateValues = (date) => {

    
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();


    return {
        day: dd,
        month: mm,
        year: yyyy
    }

}

export const convertStringDateToDateObject = (stringDate) => {

  let date;

  if(stringDate){

    let splitDate = stringDate.split('/');
    let month = splitDate[1] - 1; //Javascript months are 0-11
  
    date = new Date(splitDate[2], month, splitDate[0]);

    return date; 

  }else{
    return '';
  }

}

export const removeBlankDates = (data, passedStartDate, passedEndDate) => {

  let arr = [];

  let startDate;

  let endDate;

  passedStartDate === 0 ? startDate = new Date(`2013-01-01`) : startDate = new Date(`${passedStartDate.year}-${passedStartDate.month}-${passedStartDate.day}`);

  passedEndDate === 0 ?     endDate = new Date() : endDate = new Date(`${passedEndDate.year}-${passedEndDate.month}-${passedEndDate.day}`);


  data.forEach((val, index) => {
         let valDate = new Date(`${val.Year}-${val.Month}-01`);

         if(valDate > startDate && valDate < endDate){
           arr.push(val);
         }
  })

  return arr;

}

export async function getRowCountOnDB(db){

  try {
    const idToken = await firebase.auth().currentUser.getIdToken()
      var responseData = await Axios.post(`${devAPI}/api/get/getrowcount`, {databaseName: db}, {headers: {authorization: idToken}})

      return responseData.data

    } catch(error) {

      return null;
    }

}
  

  export async function getMethodsOfSupportBarChart(location, startDate, endDate){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/methodsofsupportbarchart`, {startDate: startDate, endDate: endDate, location: location}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getWhoTheyAreBarchart(location, startDate, endDate){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/whoTheyAreBarchart`, {startDate: startDate, endDate: endDate, location: location}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getLocalHubBarChart(location, startDate, endDate){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/localHubBarChart`, {startDate: startDate, endDate: endDate, location: location}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getLocalHubLineGraph(location, option, startDate, endDate){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/localhubLineGraph`, {startDate: startDate, endDate: endDate, location: location, option: option}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getMethodsOfSupportLineGraph(location, supportType, startDate, endDate){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/newclientslinegraph`, {startDate: startDate, endDate: endDate, location: location, supportType: supportType}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getNewClientsLineGraph2(location, currentSelectOption, startDate, endDate){

    try {
      const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/newclientslinegraph2`, {startDate: startDate, endDate: endDate, location: currentSelectOption}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }


  export async function getRelatedToReport(location, startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/relatedto`, {startDate: startDate, endDate: endDate, location: location}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getEthnicityReport(location, startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/ethncityBarChart`, {startDate: startDate, endDate: endDate, location: location}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getIssuesdentifiedreport(location, startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/issuesidentified`, {startDate: startDate, endDate: endDate, location: location}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getHearAboutUsReport(location, startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/hearaboutus`, {startDate: startDate, endDate: endDate, location: location}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getCallersRelationshipToUserReport(location, startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/CallersRelationshiptoUser`, {startDate: startDate, endDate: endDate, location: location}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getNewClientsBarChart(location, startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/newClientsBarChart`, {startDate: startDate, endDate: endDate, location: location}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getSupportGroupsBarChart(location, startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/supportGroupsBarChart`, {startDate: startDate, endDate: endDate, location: location}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getHearAboutUsLineGraph(location, option, startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/hearaboutuslinegraph`, {startDate: startDate, endDate: endDate, location: location, option: option}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getCallersRelationshipToUserLineGraph(location, option, startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/CallersRelationshiptoUserLineGraph`, {startDate: startDate, endDate: endDate, location: location, option: option}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getIssuesdentifiedLineGraph(location, issue, startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/issuesidentifiedlinegraph`, {startDate: startDate, endDate: endDate, location: location, issue: issue}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getRelatedToLineGraph(location, drug, startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/relatedtolinegraph`, {startDate: startDate, endDate: endDate, location: location, drug: drug}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }



  export async function getLinkedToHubReport(startDate, endDate){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/linkedtohub`, {startDate: startDate, endDate: endDate}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function setExitDateToDB(date, contactId){

   

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/create/exitdate`, {date: date, contactId: contactId}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }

  export async function getNewClientsReportCount(passedObj){

    try {

        const idToken = await firebase.auth().currentUser.getIdToken()
        var responseData = await Axios.post(`${devAPI}/api/get/newclientreportcount`, {startDate: passedObj.startDate, endDate: passedObj.endDate, location: passedObj.location, databaseName: passedObj.db}, {headers: {authorization: idToken}})

        return responseData.data

      } catch(error) {

        return null;
      }

  }




