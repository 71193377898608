// date-fns
import DateAdapter from '@mui/lab/AdapterDateFns';
import React, {useState, useEffect} from 'react'
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

//import MonthPicker from '@mui/lab/MonthPicker';

export default function DatePicker(props) {
  const [value, setValue] = React.useState(props.date);

  const {label } = props;

  const handleChange = (newValue) => {
    setValue(newValue);
    props.onDateChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
        <DesktopDatePicker
          label={label}
          inputFormat="dd/MM/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
    </LocalizationProvider>
  );
}