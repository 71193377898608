import React, {useState, useEffect} from 'react'
import * as Service from "../../services/employeeService";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';
import * as Axios from 'axios';
import Grid from '@mui/material/Grid';
import DatePicker from '../controls/DatePicker2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const useStyles = makeStyles(theme => ({
    tableWrap: {
        width: "100%",
        overflowX: "auto",
        height: 400
    },
    tableMinWidth: {
        minWidth: 1000
      }
}))


export default function ReportGrid() {

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [tableDataLinkedToHub, setTableDataLinkedToHub] = useState(false);
    const columnsLinkedToHub = Service.getTableColumns('linkedToHub');
    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);

     ////////////////////////////////////////////////////////////////////////
const fetchTableData = () => {

    setIsLoading(true);

    let i = 1; 

    Service.getLinkedToHubReport(startDate, endDate).then(data => {

        data.forEach(row => {
            row.id = i;
            i++;
        })

         setTableDataLinkedToHub(data);

         setIsLoading(false);

    }).catch(err => {

        console.log(err);

    })

}


useEffect(() => {
    
    fetchTableData();

}, [])


useEffect(() => {

    setIsLoading(true);

    fetchTableData();

}, [startDate, endDate])


const onDateChangeStart = (date) => {
  
    const dateVals = Service.seperateDateValues(date);
  
    setStartDate(dateVals);
  
  }
  
const onDateChangeEnd = (date) => {

    const dateVals = Service.seperateDateValues(date);

    setEndDate(dateVals);

}



    return (
        <>    

          <Box>
              
           <Typography variant="h5" gutterBottom component="div" sx={{ mb: 4}}>
            Linked to Hub
           </Typography>

            <Grid container spacing={2} sx={{ mb: 4 }}>


                <Grid item xs={12}>

                    <DatePicker label='Start Date' onDateChange={onDateChangeStart} />

                </Grid>

                <Grid item xs={12}>

                    <DatePicker label='End Date' onDateChange={onDateChangeEnd} />

                </Grid>

            </Grid>


            {tableDataLinkedToHub &&
              <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <div className={classes.tableWrap}>
                            <DataGrid 
                                rows={tableDataLinkedToHub}
                                columns={columnsLinkedToHub}
                                pageSize={50}
                                initialState={{ pinnedColumns: { left: ['Linked to Hub'], right: [] } }}
                                components={{ Toolbar: GridToolbar }}
                            />
                        </div>
                </Grid>
              </Grid>
            }   
        </Box>   
        </>
    )
}
