import React, {useState, useEffect} from 'react'
import PageHeader from "../components/PageHeader";
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import Grid from '@mui/material/Grid';
import { Paper, Toolbar } from '@material-ui/core';
import ConfirmDialog from '../components/ConfirmDialog';
import Notification from '../components/Notification';
import * as Service from "../services/employeeService";
import { Link } from "react-router-dom";
import TableWithControls from '../components/Table/TableWithControls';
import { useSearchParams } from "react-router-dom";

export default function SupportGroup() {

    const [tableData, setTableData] = useState({});
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    let [searchParams, setSearchParams] = useSearchParams();
    const paramType = searchParams.get("type");
    const paramSubmit = searchParams.get("submit");

    useEffect(() => {

        if(paramSubmit === 'success'){
            
            setNotify({
                isOpen: true,
                message: `${paramType}ed Successfully`,
                type: 'success'
            })

        }else if(paramSubmit === 'success'){

            setNotify({
                isOpen: true,
                message: `${paramType} Failed`,
                type: 'error'
            })

        }
        

    }, [])


    return (
        <>            
            <PageHeader
                title='Support Group'
                subTitle="Add, Edit or Delete Support Groups"
                icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
            />
            <Paper>

                <Grid container spacing={2} sx={{ p: 4, mb: 2 }}> 
                    <Grid item xs={12}>
              
                        <TableWithControls 
                            tableData={tableData} 
                            tableName='supportGroup'
                        />
              
                    </Grid>
                </Grid>

            </Paper>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}

