import React, {useContext, useState, useEffect} from 'react';
import {auth} from '../firebase';
import {firebase} from '../firebase';

const AuthContext = React.createContext({
    
});

export function useAuth(){
    return useContext(AuthContext);
}


export function AuthProvider({children}) {

    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true);
    const [bearerToken, setBearerToken] = useState('');

    function login(email, password){
        return auth.signInWithEmailAndPassword(email, password);
    }

    //this returns a promise so does one above. 
    function logout(){
       return auth.signOut();
    }
        

    //using use effect for the below so it only runs once. Its not part of the render, only runs when the component is mounted.
    useEffect(() => {
        //firebase has a way of notifying you to set the user, the aboe login function doesnt set.. 
        //it returns a method for us to unsubscribe to the listender in the future. 
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false)
        })

        //
        return unsubscribe;
    }, [])



    const value = {
        currentUser,
        login,
        logout
    }


    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}