import React, {useState, useEffect} from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton } from '@material-ui/core'
import Controls from "./controls/Controls";
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import { makeStyles } from '@material-ui/styles';
import * as Service from "../services/employeeService";
import DatePicker from '../components/controls/DatePicker2';



const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
}))

export default function ConfirmDateDialog(props) {

    const { confirmDialog, setConfirmDialog, confirmDialogHideSubmit } = props;
    const classes = useStyles()

    const onDateChange = (date) => {

        props.setConfirmDialogHideSubmit(true);
        props.setExitDate(date);
        setConfirmDialog({ ...confirmDialog, date: 'helloooo2' })
      
    }

    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton disableRipple className='test'>
                    <NotListedLocationIcon  />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
            </DialogContent>
            <DialogContent className={classes.dialogContent}>
                <DatePicker label='Exit Date' onDateChange={onDateChange} />
            </DialogContent>
            <DialogActions className={classes.dialogAction}>

                { !confirmDialogHideSubmit && 

                    <>
                    <Controls.Button
                    text="No"
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false, date: 'helloooo' })} />
                    <Controls.Button
                    text="Yes"
                    onClick={confirmDialog.onConfirm}
                    color="error" />
                    </>

                }
            </DialogActions>
        </Dialog>
    )
}
