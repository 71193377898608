import React, {useState, useEffect} from 'react'
  import Chip from '@mui/material/Chip';
  import Autocomplete from '@mui/material/Autocomplete';
  import TextField from '@mui/material/TextField';
  import Stack from '@mui/material/Stack';
  
  export default function AutoSelect(props) {

    const {options, defaultValues, type, label, placeholder, defaultVals} = props;


  const onChange = (event, newValue) => {
    console.log('on auto select change inner', newValue);
    props.onChange(newValue);

  }


    return (
        <>
        <Autocomplete
         onChange={onChange}
          multiple
          id="tags-outlined"
          options={options}
      
          defaultValue={defaultValues ? defaultValues : []}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
            />
          )}
        />
    </>
    );
  }
  