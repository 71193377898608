import React, {useState, useEffect} from 'react'
import {  Typography, Paper } from '@material-ui/core';
import Box from '@mui/material/Box';
import PageHeader from "../components/PageHeader";
import Grid from '@mui/material/Grid';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import {Form} from '../components/useForm';
import { useParams } from "react-router-dom";
import * as Service from "../services/employeeService";
import Controls from "../components/controls/Controls";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import * as Axios from 'axios';
import { useSearchParams } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import DatePicker from '../components/controls/DatePicker2';

import ConfirmDialog from '../components/ConfirmDialog';
import Notification from '../components/Notification';


function formatDate(dateString) {
    // Split the date string into parts
    const parts = dateString.split('/');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    // Return the formatted date string in yyyy-mm-dd format
    return `${year}-${month}-${day}`;
}

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}))


export default function SupportGroupView() {

    const classes = useStyles();
    const params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const formID = searchParams.get("SupportGroupID");
    const [isLoading, setIsLoading] = useState(true);
    const [formOptions, setFormOptions] = useState();
    const [errors, setErrors] = useState({});

    const [supportGroupFieldGroupsSection, setSupportGroupFieldGroupsSection] = useState();
    const [carersAttendedFieldGroupsSection, setCarersAttendedFieldGroupsSection] = useState();
    const [addFSWFieldGroupsSection, setAddFSWFieldGroupsSection] = useState();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })


    const navigate = useNavigate();
    const isEdit = searchParams.get("edit") === 'true' ? true : false;


 ////////////////////////////////////////////////////////////////////////
useEffect(() => {

    Service.getSupporGroupFormFieldsFromDB().then((data) => {

        setFormOptions(data);

        setIsLoading(false);

    });

}, [])
 
 ////////////////////////////////////////////////////////////////////////
    

 ////////////////////////////////////////////////////////////////////////
const fetchSupportLogData = () => {

    setIsLoading(true);

    if(formID !== 'undefined'){

        Service.getTokenForAPI().then(idToken => {
            
            Axios.get(`${Service.devAPI}/api/get/supportgroup/${formID}`, {headers: {authorization: idToken}}).then((data) => {

                console.log('GET-SUPPORTGROUP:', data);

                setSupportGroupFieldGroupsSection(data.data['tblSupportGroup'][0]);
                setCarersAttendedFieldGroupsSection({'carers' : data.data['tblSupportGroupAttended']});
                setAddFSWFieldGroupsSection(data.data['tblSupportGroupFSW']);
    
                setIsLoading(false);
    
    
            }).catch((error) => {
    
                console.log("Error getting support group Data:", error);
    
                setIsLoading(false);
    
            });
    
        })
    }else{
            setSupportGroupFieldGroupsSection(true);
            setCarersAttendedFieldGroupsSection(true);
            setAddFSWFieldGroupsSection(true)
            setIsLoading(false);
    }

}

 ////////////////////////////////////////////////////////////////////////


 ////////////////////////////////////////////////////////////////////////
const GetSupportLogDataEffect = () => {
    useEffect(() => {
        fetchSupportLogData();
    }, [])
}

GetSupportLogDataEffect();

 ////////////////////////////////////////////////////////////////////////


 const validate = () => {

    let temp = {};

    temp['support_group_name'] = supportGroupFieldGroupsSection['support_group_name'] ? '' : 'This field is required';
    temp['Hub'] = supportGroupFieldGroupsSection['Hub'] ? '' : 'This field is required';
    
    setErrors({
        ...temp
    })

    return Object.values(temp).every(x => x == '');

}



 ////////////////////////////////////////////////////////////////////////
const handleSubmit = () => {

    setIsLoading(true);

    let promises = [];

    if(supportGroupFieldGroupsSection['Support Details']){

        supportGroupFieldGroupsSection['Support Details'] = supportGroupFieldGroupsSection['Support Details'].replace(/\"/g, "\\\"");

    }else{

        alert('supportGroupFieldGroupsSection["Support Details"] is Undefined');

    }

    if(isEdit){

        const fieldGroups = {
            'tblSupportGroup' : supportGroupFieldGroupsSection,
            'tblSupportGroupAttended' : carersAttendedFieldGroupsSection,
            'tblSupportGroupFSW' : addFSWFieldGroupsSection,
            'supportGroupID' : supportGroupFieldGroupsSection['SupportGroupID']
        }

        Service.updateSupportGroupToDB(fieldGroups).then(data => {
      
            console.log('SUCCESSFULLY UPDATE ALL SUPPORT GROUP DATA', data);

            if(data === null){
                throw 'Error!';
              } else{
                navigate('/supportgroup/?submit=success&type=Submit');  
              }

       }).catch(error => {
           
            console.log('Error Updating support group data', error);

            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })

            setNotify({
                isOpen: true,
                message: 'Updated Failed',
                type: 'success'
            })
        
       })

    }else{

        if(validate()){

            const uid = Service.generateUID();

            supportGroupFieldGroupsSection.SupportGroupID = uid;
            carersAttendedFieldGroupsSection.supportGroupID = uid;
            addFSWFieldGroupsSection.supportGroupID = uid;

            supportGroupFieldGroupsSection.formatted_date_logged = formatDate(supportGroupFieldGroupsSection['Held On']);

            console.log('supportGroupFieldGroupsSection', supportGroupFieldGroupsSection);
            console.log('SUPPORT-GROUP-ATTENDED', carersAttendedFieldGroupsSection);

              promises.push(Service.submitToDB(supportGroupFieldGroupsSection, 'supportGroup'))
              promises.push(Service.submitObjToDB(carersAttendedFieldGroupsSection, 'supportGroupAttended'))
              promises.push(Service.submitObjToDB(addFSWFieldGroupsSection, 'supportGroupFSW'))
    
            Promise.all(promises).then(data => {

                if(data === null){

                    throw 'Error!';

                  } else{

                    Service.getTokenForAPI().then(idToken => {

                        Axios.get(`${Service.devAPI}/api/get/supportGroupAttendedDBcheck/${uid}`, {headers: {authorization: idToken}}).then((data) => {

                            if(data.data.length > 0){

                                let localArr = [];
                                let dbArr = [];
                                

                                carersAttendedFieldGroupsSection['carers'].forEach(carer => {

                                    localArr.push(carer['UniqueIdentifier']);

                                })

                                data.data.forEach(carer => {

                                    dbArr.push(carer['Person Attended']);

                                })

                                if(localArr.length === dbArr.length){

                                            navigate('/supportgroup/?submit=success&type=Submit'); 
                                            setSupportGroupFieldGroupsSection(true);
                                            setCarersAttendedFieldGroupsSection(true);
                                            setAddFSWFieldGroupsSection(true)

                                }else{

                                    let localArrStr = localArr.toString();
                                    let dbArrStr = dbArr.toString();

                                    alert(`Support Group carers does not match to DB \n \n carers submitted: \n ${localArrStr} \n \n carers in DB: \n ${dbArrStr} `);

                                }

                            }
                
                        }).catch((error) => {
            
                            console.log("Error getting support group data:", error);
            
                            setIsLoading(false);
            
                        });

                    })

                }
                
            }).catch(error => {

                console.log('error', error)
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false
                })
    
                setNotify({
                    isOpen: true,
                    message: 'Updated Failed',
                    type: 'success'
                })
            })

        }else{

            setIsLoading(false);

            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })

        }

    }
}

 ////////////////////////////////////////////////////////////////////////

 ////////////////////////////////////////////////////////////////////////

const onChangeAddFSWFieldGroups = (data) => {

    setAddFSWFieldGroupsSection({
        ...data
    })
}

const handleInputChange = e => {

    const { name, value } = e.target

    setSupportGroupFieldGroupsSection({
        ...supportGroupFieldGroupsSection,
        [name]: value
    })

}

const onCarersAttendedFieldGroups = (data) => {

    setCarersAttendedFieldGroupsSection({
        'carers' : data
    })

}

const onDateChangeStart = (date) => {
        
    let formattedDate = Service.seperateDateValues(date);

    setSupportGroupFieldGroupsSection({
        ...supportGroupFieldGroupsSection,
        'Held On': `${formattedDate.day}/${formattedDate.month}/${formattedDate.year}`
    })
  
}

useEffect(() => {

    if(isEdit === false){

        let formattedDate = Service.seperateDateValues(new Date());

        setSupportGroupFieldGroupsSection({
            ...supportGroupFieldGroupsSection,
            'Held On': `${formattedDate.day}/${formattedDate.month}/${formattedDate.year}`
        })

        
    }
}, [])

 ////////////////////////////////////////////////////////////////////////



    return (

        <>
                <Box sx={{ mb: 2}}>
                    <Controls.Button
                        text="Back"
                        component={Link} 
                        to={`/supportgroup`}
                    />
                </Box>
            <PageHeader
                title={isEdit ? 'Edit Support Group' : 'Create Support Group'}
                icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
            />
 
            {(formOptions && supportGroupFieldGroupsSection && addFSWFieldGroupsSection && carersAttendedFieldGroupsSection && !isLoading) && 
                <Form>
                    <Paper >
                        <Grid container sx={{ p: 4, mb: 2}}>
                            <Typography variant="h5" sx={{ my: 4 }} component={'span'} >1. Support Group Details</Typography>
                            

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={6}>
                                                <Controls.Input
                                                    label="Support Group Name"
                                                    name="Support Group Name"
                                                    value={supportGroupFieldGroupsSection['Support Group Name']}
                                                    onChange={handleInputChange}
                                                />
                                                <Controls.Select
                                                    label="Hub"
                                                    name="Hub"
                                                    value={supportGroupFieldGroupsSection['Hub']}
                                                    onChange={handleInputChange}
                                                    options={formOptions['single']['hubName']}
                                                    error={errors['Hub']}
                                                />
                                    </Grid>
                                    <Grid item xs={6}>
                                            <DatePicker 
                                                    onDateChange={onDateChangeStart} 
                                                    label='Held On'
                                                    date={isEdit ? Service.convertStringDateToDateObject(supportGroupFieldGroupsSection['Held On']) : new Date()}
                                                />
                                                <Controls.Select
                                                    label="Support Group Name (New)"
                                                    name="support_group_name"
                                                    value={supportGroupFieldGroupsSection['support_group_name']}
                                                    onChange={handleInputChange}
                                                    options={['Southampton SG', 'Havant SG', 'Fareham SG', 'Gosport SG', 'Eastleigh SG', 'Winchester SG', 'Basingstoke SG', 'Aldershot SG', 'Andover SG', 'New Forest SG', 'Wellbeing', 'Stepping Stone', 'Bereavement', 'LLC', 'Hope', 'Partners', 'Jigsaw journey', 'Acupuncture',  'Any Other']}
                                                    error={errors['support_group_name']}
                                                />
                                    </Grid>
                                    <Grid item xs={12}>
                                                <Controls.Input
                                                    label="Support Details"
                                                    name="Support Details"
                                                    value={supportGroupFieldGroupsSection['Support Details']}
                                                    onChange={handleInputChange}
                                                    multiline
                                                    rows={5}
                                                />
                                    </Grid>
                                </Grid>


                        </Grid>
                    </Paper>
                    <Paper >
                        <Grid container sx={{ p: 4, mb: 2}}>
                            <Typography variant="h5" sx={{ my: 4 }} component={'span'} >2. Add FSW</Typography>

                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={6}>
                                                <Controls.AutoSelect2 
                                                    defaultValues={isEdit ? addFSWFieldGroupsSection : false} 
                                                    options={formOptions['fswObj']} 
                                                    onChange={onChangeAddFSWFieldGroups}/>
                                            </Grid>
                                        </Grid>

                        </Grid>
                    </Paper>
                    <Paper >
                        <Grid container sx={{ p: 4, mb: 2}}>
                            <Typography variant="h5" sx={{ my: 4 }} component={'span'} >3. Carers Attended</Typography>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={6}>
                                                
                                                    <Controls.AutoSelect 
                                                        defaultValues={isEdit ? carersAttendedFieldGroupsSection['carers'] : false} 
                                                        options={formOptions['careersObj']} 
                                                        onChange={onCarersAttendedFieldGroups}
                                                        label="Select Which Carers Attended"
                                                        placeholder="Carer"
                                                        type="carer"
                                                    />

                                            </Grid>
                                        </Grid>

                        </Grid>
                    </Paper>
                        <Controls.Button
                            text={isEdit === true ? 'Update' : 'Submit'}
                            color="warning"
                            onClick={() => {
                                setConfirmDialog({
                                    isOpen: true,
                                    title: 'Are you sure to update this record?',
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => { handleSubmit() }
                                })
                            }}
                        />
                    
                </Form>
            }
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}
