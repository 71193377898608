import React, {useState, useEffect} from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from "react-router-dom";
import {useAuth} from '../context/auth-context'; 
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ContactsIcon from '@mui/icons-material/Contacts';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import * as Service from "../services/employeeService";
import BarChartIcon from '@mui/icons-material/BarChart';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const { open, setOpen }= props;
  const {currentUser, logout} = useAuth();
  const [error, setError] = React.useState('');
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);


  let listItems = [
    {
        text: 'Contacts',
        to: 'contacts',
        icon: <ContactsIcon />
    },
    {
      text: 'Support Group',
      to: 'supportGroup',
      icon: <ContactsIcon />
  },
  {
    text: 'Reporting',
    to: 'reporting',
    icon: <BarChartIcon />
},
    {
        text: 'Logout',
        to: '/login/?logout=true',
        icon: <LogoutIcon />
    }];

  useEffect(() => {

    // Service.checkManagementAccess('test@test.com').then(data => {

    //   if(data === true){
        
    //     listItems = [
    //       {
    //           text: 'Contacts',
    //           to: 'contacts',
    //           icon: <ContactsIcon />
    //       },
    //       {
    //         text: 'Support Group',
    //         to: 'supportGroup',
    //         icon: <ContactsIcon />
    //     },
    //       {
    //           text: 'Reporting',
    //           to: 'reporting',
    //           icon: <SignalCellularAltIcon />
    //       },
    //       {
    //           text: 'Logout',
    //           to: '/login',
    //           icon: <LogoutIcon />
    //       }];

    //   }
    // })

    setIsLoading(false);
  
  }, [])



  async function handleLogOut() {
    console.log('Logging Out User');

    setError('');

    try{
      await logout();
      setOpen(false);
      navigate('/login')
    }catch{
      setError('Failed to logout')
    }
  }

  const handleListItemClick = (event, index) => {
      setSelectedIndex(index);

      if(index === 3){
        handleLogOut();
      }
  };

   ////////////////////////////////////////////////////////////////////////

  return (
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
        </DrawerHeader>
        <Divider />
        {isLoading === false &&
          
          <List>
         {listItems.map((item, index) => {
           const {text, to, icon} = item
           return (
               <ListItem 
                   button 
                   key={text} 
                   to={to}
                   selected={selectedIndex === index}
                   component={Link} 
                   onClick={(event) => handleListItemClick(event, index)}>
                   <ListItemIcon>
                   {icon}
                   </ListItemIcon>
                   <ListItemText primary={text} />
               </ListItem>
           )
         })}
       </List>

        
        }
      

      </Drawer>
  );
}