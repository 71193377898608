import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {useAuth} from '../context/auth-context'; 
import MuiAppBar from '@mui/material/AppBar';



const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function MenuAppBar(props) {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { open, setOpen } = props;
  const {currentUser, logout} = useAuth();
  const [error, setError] = React.useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  async function handleLogOut() {
    console.log('Logging Out User');

    setError('');

    try{
      await logout();
      setOpen(false);
      navigate('/login')
    }catch{
      setError('Failed to logout')
    }
  }

  useEffect(() => {
    console.log('WINDOW HREF IS', window.location.href)
    if(window.location.href === 'http://localhost:3000/' 
        || window.location.href === 'http://localhost:3000/login' 
        || window.location.href === 'https://pslhub.uk/login'
        || window.location.href === 'https://pslhub.uk/'){
      console.log('Set OPEN TO FALSE')
      setOpen(false);
    }else{
      console.log('Set OPEN TO TRUE')
      setOpen(true);
    }
  }, [])

  return (
    <AppBar position="fixed" open={open}>
    <Toolbar className="header">
      {/* <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton> */}
      <Typography variant="h6" noWrap component="div">
        PSL HUB
      </Typography>


      {auth && (
            <div className="header-login-wrap">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                className="header-login-icon"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogOut}>Log out</MenuItem>
              </Menu>
              <Typography variant="h6" noWrap component="div">
              {currentUser && currentUser.email}
              </Typography>
            </div>
          )}


    </Toolbar>
  </AppBar>
  );
}