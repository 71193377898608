import React, { useState, useEffect } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../controls/Controls";

export default function AddressAndContactDetails(props) {

    const [formData, setFormValues] = useState(props.fieldValues);
    const [thisSectionFormData, setThisSectionFormData] = useState([]);
    const {formOptions} = props;

    useEffect(() => {
        
        setThisSectionFormData({
            "House Name or Number" : formData['House Name or Number'],
            'Street Name' : formData['Street Name'],
            'Town/City' : formData['Town/City'],
            'Email Address' : formData['Email Address'],
            'Telephone (Mobile)' : formData['Telephone (Mobile)'],
            'Postcode' : formData['Postcode'],
            'Telephone (Home)' : formData['Telephone (Home)'],
            'Telephone (Work)' : formData['Telephone (Work)'],
            'Contact Preference' : formData['Contact Preference']
        })

    }, [])

    const handleInputChange = e => {

        const { name, value } = e.target

        setThisSectionFormData({
            ...thisSectionFormData,
            [name]: value
        })

        setFormValues({
            ...formData,
            [name]: value
        })
    }

    useEffect(() => {
        props.onCompleteFormSection(thisSectionFormData)
     
    }, [thisSectionFormData])

   


    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Controls.Input
                        label="House Name or Number"
                        name="House Name or Number"
                        value={formData['House Name or Number']}
                        onChange={handleInputChange}
                    />
                    <Controls.Input
                        label="Street Name"
                        name="Street Name"
                        value={formData['Street Name']}
                        onChange={handleInputChange}
                    />
                    <Controls.Input
                        label="Town/City"
                        name="Town/City"
                        value={formData['Town/City']}
                        onChange={handleInputChange}
                    />
                      <Controls.Input
                        label="Email Address"
                        name="Email Address"
                        value={formData['Email Address']}
                        onChange={handleInputChange}
                    />
                    <Controls.Input
                            label="Telephone (Mobile)"
                            name="Telephone (Mobile)"
                            value={formData['Telephone (Mobile)']}
                            onChange={handleInputChange}
                        />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                            label="Postcode"
                            name="Postcode"
                            value={formData['Postcode']}
                            onChange={handleInputChange}
                        />
                        <Controls.Input
                            label="Telephone (Home)"
                            name="Telephone (Home)"
                            value={formData['Telephone (Home)']}
                            onChange={handleInputChange}
                        />
                        <Controls.Input
                            label="Telephone (Work)"
                            name="Telephone (Work)"
                            value={formData['Telephone (Work)']}
                            onChange={handleInputChange}
                        />
                        <Controls.Select
                            label="Contact Preference"
                            name="Contact Preference"
                            value={formData['Contact Preference']}
                            onChange={handleInputChange}
                            options={formOptions['single']['ContactPreference']}
                        />
                </Grid>
            </Grid>
    )
}
