import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@mui/material/Typography';



export default function Dashboard() {

    return (
      <>
        <Typography variant="h2">Dashboard</Typography>
      </>
    )
}
