import React, {useState, useEffect} from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../components/controls/Controls";
import { Form } from '../components/useForm';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import {useAuth, currentUser} from '../context/auth-context';
import { useSearchParams } from "react-router-dom";


export default function Login(props) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const {login, currentUser, logout} = useAuth();
    const [emailRef, setEmailRef] = useState('');
    const [passwordRef, setPasswordRef] = useState('');

    let [searchParams, setSearchParams] = useSearchParams();
    const paramLogout = searchParams.get("logout");

    async function handleSubmit(e) {
        e.preventDefault();

        try{   
            setLoading(true);
            setError('')
           await login(emailRef, passwordRef); 
           props.setOpen(true);
           navigate('/contacts');
        } catch{
            setError('Failed to login')
        }
        setLoading(false);
    }

    const onChangeInput = e => {
        const { name, value } = e.target

        if(name === 'email'){
            setEmailRef(value);
        }else{
            setPasswordRef(value);
        }
    }

    async function handleLogOut() {
        console.log('Logging Out User');
    
        setError('');
    
        try{
          await logout();
          props.setOpen(false);
        }catch{
          setError('Failed to logout')
        }
      }

    useEffect(() => {
        if(paramLogout === 'true'){
            handleLogOut();
        }
      }, [])
      


    return (
        <Grid container className="login-container">
            <Form onSubmit={handleSubmit}>
                    <Paper className="login-form">

                        <div className="login-image">
                            <img src='https://www.pslcharity.org.uk/wp-content/uploads/2020/05/parent-support-link-logo2.png'></img>
                        </div>

                        <Box className="login-wrap">
                           {error && <Alert severity="error">{error}</Alert>}
                            <Controls.Input
                                label="Email"
                                name="email"
                                value={emailRef}
                                onChange={onChangeInput}
                            />
                            <Controls.Input
                                label="Password"
                                name="Password"
                                type='password'
                                value={passwordRef}
                                onChange={onChangeInput}
                            />
                             <Controls.Button
                                text="Submit"
                                type="submit"
                                color="primary"
                                variant="outlined" 
                                disabled={loading}
                                className="login-form-submit-btn"
                            />
                            
                        </Box>
                    </Paper>
                </Form>
        </Grid>
    )
}
