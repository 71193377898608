import React, {useState, useEffect} from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { Form } from '../../components/useForm';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DetailsandRelationships from '../../components/ContactFormFieldsGroups/DetailsandRelationships';
import EmergencyDetails from '../../components/ContactFormFieldsGroups/EmergencyDetails';
import AddressAndContactDetails from '../../components/ContactFormFieldsGroups/AddressAndContactDetails';
import HubAndTreatment from '../../components/ContactFormFieldsGroups/HubAndTreatment';
import LoggedBy from '../../components/ContactFormFieldsGroups/LoggedBy';
import * as Service from "../../services/employeeService";
import * as Axios from 'axios';



const steps = ['Details and Relationship', 'Emergency Details', 'Address and Contact Details', 'Hub and Treatment', 'Logged By'];


export default function EmployeeForm(props) {

  const {tableData, fetchTableData} = props; 
  const {formData, setFormData} = props;
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const {recordForEdit} = props;
  const {selectedRows} = props;
  const { setOpenPopup } = props;
  const [formOptions, setFormOptions] = useState();
  const { setNotify } = props;

    useEffect(() => {

        let randomID = Service.generateUID();

        console.log('RANDOM ID IS:', randomID)

        setFormData({
            ...formData,
            'UniqueIdentifier': randomID
        });

        Service.getContactsFormFieldsFromDB().then((data) => {

            setFormOptions(data);
        });

    }, [])




  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };



    const handleSubmit = e => {

        e.preventDefault()

        if(recordForEdit !== true){

            

            for(const key in formData) {
            
                if(formData[key] == undefined){
                    console.log(`${key}: ${formData[key]}`);
                    formData[key] = '';
                }
                
            }

              console.log('FORM DATA TEMP SUBMIT', formData)

            Service.createContactOnDB(formData).then(() => {

                 //RESET THE FORM
                 let contactData = Service.initalformContacts;
                 console.log('FORMDATA SUBMITTED', formData);
                 contactData['UniqueIdentifier'] = Service.generateUID();
                 console.log('NEW RANDOM ID is', contactData['UniqueIdentifier'])
                 setFormData(contactData);
 
                 fetchTableData();

                 setOpenPopup(false);

                 setNotify({
                     isOpen: true,
                     message: 'Contact Added Successfully',
                     type: 'success'
                 })

            }).catch(err => {
                console.log("Error Posting New Contact:", err);
    
                    fetchTableData();

                    setOpenPopup(false);

                    setNotify({
                        isOpen: true,
                        message: 'Error Adding Contact',
                        type: 'error'
                    })
            });
        }
    }


const saveFormSectionData = (enteredFormSectionData) => {

    setFormData({
        ...formData,
        ...enteredFormSectionData
    })
}


    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>

                    {formOptions &&
                        <Box sx={{my: 10}}>
                            {activeStep === 0 && <DetailsandRelationships fieldValues={formData} formOptions={formOptions} onCompleteFormSection={saveFormSectionData} />}
                            {activeStep === 1 && <EmergencyDetails fieldValues={formData} formOptions={formOptions} onCompleteFormSection={saveFormSectionData} />}
                            {activeStep === 2 && <AddressAndContactDetails fieldValues={formData} formOptions={formOptions} onCompleteFormSection={saveFormSectionData} />}
                            {activeStep === 3 && <HubAndTreatment fieldValues={formData} formOptions={formOptions} onCompleteFormSection={saveFormSectionData} />}
                            {activeStep === 4 && <LoggedBy fieldValues={formData} formOptions={formOptions} onCompleteFormSection={saveFormSectionData} />}
                        </Box>
                    }

         




                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        >
                        Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                            Skip
                        </Button>
                        )}

                        { activeStep === 4 ? (
                            <Controls.Button
                            text="Submit"
                            type="submit"
                            color="primary"
                            variant="outlined" />
                            ) 
                            :  
                            (<Button onClick={handleNext}>Next</Button> ) }

                    </Box>
                    </React.Fragment>
                )}
                </Box>
            </Grid>
        </Form>
    )
}
