  import React, { useState, useEffect } from 'react'
  import Chip from '@mui/material/Chip';
  import Autocomplete from '@mui/material/Autocomplete';
  import TextField from '@mui/material/TextField';
  import Stack from '@mui/material/Stack';
  
  export default function AutoSelect(props) {

    const {options, defaultValues} = props;


  const onChange = (event, newValue) => {

    props.onChange(newValue);

  }


    return (
        <>
        <Autocomplete
         onChange={onChange}
          multiple
          id="tags-outlined"
          options={options}
          getOptionLabel={(option) => `${option['FSW_ID']} - ${option['First Name']} ${option['Surname']}`}
          defaultValue={defaultValues ? defaultValues : []}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select FSW That Attended"
              placeholder="FSW"
            />
          )}
        />
    </>
    );
  }
  