import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import SelectObj from "./SelectObj";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import ActionButton from "./ActionButton";
import Accordion2 from "./Accordion";
import Textarea from "./Textarea";
import AutoSelect from './AutoSelect';
import AutoSelect2 from './AutoSelect2';
import AutoSelect3 from './AutoSelect3';
import SelectFieldObj from './SelectFieldObj';
const Controls = {
    Input,
    RadioGroup,
    Select,
    SelectObj,
    SelectFieldObj,
    Checkbox,
    DatePicker,
    Button,
    ActionButton,
    Accordion2,
    Textarea,
    AutoSelect,
    AutoSelect2,
    AutoSelect3
}

export default Controls;