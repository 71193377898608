import * as React from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';

export default function MinHeightTextarea(props) {

  const { name, label, value,error=null, onChange, innerRef, ...other } = props;

  return (
    <TextareaAutosize

            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            {...other}
            {...(error && {error:true,helperText:error})}
    />
  );
}