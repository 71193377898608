import React from 'react';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import SideMenu from "../components/SideMenu";
import {CssBaseline, createMuiTheme, ThemeProvider } from '@material-ui/core';
import Header from "../components/Header";
import PageHeader from '../components/PageHeader';
import Box from '@mui/material/Box';
import PrivateRoute from '../components/PrivateRoute';
import { makeStyles } from '@material-ui/styles';
import { Navigate } from 'react-router-dom';
import Contact from '../pages/Contact/Contact';
import MonitoringTool from '../pages/MonitoringTool';
import SupportLog from '../pages/supportLog';
import Employees from "../pages/Employees/Employees";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Reporting from "../pages/Reporting";
import SupportGroup from "../pages/SupportGroup";
import SupportGroupView from "../pages/SupportGroupView";
import {AuthProvider} from '../context/auth-context';

import { styled, useTheme } from '@mui/material/styles';
import { fabClasses } from '@mui/material';

const theme = createMuiTheme({
 
})

const useStyles = makeStyles({
  
})



const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <SideMenu setOpen={setOpen} open={open} />
            <Header setOpen={setOpen} open={open} />
              <Main open={open} className="app-body">
                <DrawerHeader />
                <Routes>
                  <Route element={<PrivateRoute isLogged={true} />}>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/contacts" element={<Employees pageType="contacts" />} />
                      <Route path="/contact/:id" element={<Contact />} />
                      <Route path="/contact/new" element={<Contact />} />
                      <Route path="/loggedsupport" element={<SupportLog />} />
                      <Route path="/monitoringtool" element={<MonitoringTool />} />
                      <Route path="/reporting" element={<Reporting />} />
                      <Route path="/supportGroup" element={<SupportGroup />} />
                      <Route path="/supportgroupview" element={<SupportGroupView />} />
                  </Route>
                  <Route path="/login" element={<Login setOpen={setOpen} />} />
                  <Route path="/" element={<Navigate to="/contacts" />} />
                </Routes>
              </Main> 
            </Box>
            <CssBaseline />
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
