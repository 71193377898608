import React, { useState, useEffect } from 'react'
import EmployeeForm from "./EmployeeForm";
import PageHeader from "../../components/PageHeader";
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import { Paper, Toolbar } from '@material-ui/core';
import * as Service from "../../services/employeeService";
import Controls from "../../components/controls/Controls";
import AddIcon from '@material-ui/icons/Add';
import Popup from "../../components/Popup";
import DataTable from '../../components/DataTable';
import ConfirmDialog from '../../components/ConfirmDialog';
import Notification from '../../components/Notification';
import Grid from '@mui/material/Grid';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import * as Axios from 'axios';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
   
 
}))

const initalformData = {

    ...Service.initalformContacts
 }
 
 const columns = [
     ...Service.tableHeaderContacts
 ]


export default function Employees(props) {

    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [openPopup, setOpenPopup] = useState(false)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const [tableData, setTableData] = useState({});
    const [selectedRows, setSelectedRows] = useState([])
    const [disableEditBtn, setDisableEditBtn] = useState(true);
    const [formData, setFormData] = useState(initalformData);
    const [disableDeleteBtn, setDisableDeleteBtn] = useState(true);
    const {pageType} = props;
    let navigate = useNavigate();


////////////////////////////////////////////////////////////////////////
const resetTable = () => {

    setSelectedRows([]);

    fetchTableData();

    setConfirmDialog({
        ...confirmDialog,
        isOpen: false
    })

    setNotify({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'success'
    })
}

////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
const clearSelected = () => {
    setSelectedRows([]);
}
////////////////////////////////////////////////////////////////////////


async function fetchTableData(){

    Service.getContactsFromDB().then((data) => {
        console.log('table data', data);
        setTableData(data);
    });
}


useEffect(() => {
    fetchTableData();
}, [])



function onDelete() {

    alert('Please speak to supervisor to have this removed');

    // Service.deleteOnDB('contacts', selectedRows, tableData).then(data => {

    //     resetTable();

    // })

}


const onAddContact = () => {

    navigate(`/contact/new`);

}

const onView = () => {
    const tableItem = tableData.find(tableItem => tableItem.id === selectedRows[0]);
    navigate(`/contact/${tableItem.UniqueIdentifier}`);
}
     

    return (
        <>
            <PageHeader
                title='Contact'
                subTitle="Add, Edit or Delete Contacts"
                icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
            />
            <Paper>
                <Grid container sx={{ p: 4 }}>

                <Toolbar className="padding-0">
                    <Controls.Button
                        text="Add New"
                        startIcon={<AddIcon />}
                        className={classes.newButton}
                        onClick={() => { onAddContact(); }}
                    />
                    
                </Toolbar>
                <DataTable 
                    tableData={tableData} 
                    fetchTableData={fetchTableData}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    disableEditBtn={disableEditBtn}
                    setDisableEditBtn={setDisableEditBtn}
                    setDisableDeleteBtn={setDisableDeleteBtn}
                    columns={columns}
                    /> 

                <Toolbar className="padding-0">
                    <Controls.Button
                        text={selectedRows.length > 1 ? "Delete All" : "Delete"}
                        disabled={disableDeleteBtn}
                        onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to delete this record?',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onDelete() }
                            })
                        }}
                        sx={{ mr: 1 }}
                        color="error"
                    />
                    <Controls.Button
                    text="Clear Selected"
                    disabled={disableDeleteBtn}
                    onClick={clearSelected}
                    sx={{ mr: 1 }}
                    />
                     <Controls.Button
                        text="View / Edit"
                        disabled={disableEditBtn}
                        onClick={() => { onView(); }}
                    />
                </Toolbar>

                </Grid>
            </Paper>
            <Popup
                title={recordForEdit === true ? "Edit Contact" : "Add Contact"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <EmployeeForm
                    recordForEdit={recordForEdit}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    formData={formData}
                    setFormData={setFormData}
                    selectedRows={selectedRows}
                    fetchTableData={fetchTableData}
                    setNotify={setNotify} />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}
