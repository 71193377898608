import React, { useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';



const useStyles = makeStyles(theme => ({
    tableWrap: {
        width: "100%",
        overflowX: "auto",
        height: 400
    },
    tableMinWidth: {
        minWidth: 1000
      }
}))





export default function DataTable(props) {

    const {tableData } = props;

    const {selectedRows, setSelectedRows} = props;

    const {setDisableDeleteBtn} = props;

    const {setDisableEditBtn} = props;

    const {columns, setColumns} = props;

    const classes = useStyles();

    const handleSelectionModelChange = (newSelection) => {
        setSelectedRows(newSelection)
    }

    useEffect(() => {
        if (selectedRows.length === 1) {
            setDisableEditBtn(false);
        } else {
            setDisableEditBtn(true);
        }

        if(selectedRows.length > 0){
            setDisableDeleteBtn(false);
        }else{
            setDisableDeleteBtn(true);
        }

      }, [selectedRows]); 


    return (
            <>
            <div className={classes.tableWrap}>
                <DataGrid 
                    rows={tableData}
                    columns={columns}
                    pageSize={50}
                    checkboxSelection
                    onSelectionModelChange={handleSelectionModelChange}
                    selectionModel={selectedRows}
                    components={{ Toolbar: GridToolbar }}
                />
            </div>
           
        </>
    )
}