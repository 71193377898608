import React, {useState, useEffect} from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Controls from "../../components/controls/Controls";
import AddIcon from '@material-ui/icons/Add';
import DataTable from '../../components/DataTable';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import ConfirmDialog from '../../components/ConfirmDialog';
import Notification from '../../components/Notification';
import * as Service from "../../services/employeeService";
import * as Axios from 'axios';


export default function TableWithControls(props) {

    const {tableName} = props;
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [tableData, setTableData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([])
    const [disableEditBtn, setDisableEditBtn] = useState(true);
    const [disableDeleteBtn, setDisableDeleteBtn] = useState(true);
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const isEdit = searchParams.get("edit") === 'true' ? true : false;
    const supportLogId = searchParams.get("supportLogId");
    const params = useParams();
    const contactId = params.id;
    const columns = Service.getTableColumns(tableName);


////////////////////////////////////////////////////////////////////////
const resetTable = () => {

    setSelectedRows([]);

    fetchTableData();

    setConfirmDialog({
        ...confirmDialog,
        isOpen: false
    })

    setNotify({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'success'
    })
}

////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
const clearSelected = () => {
    setSelectedRows([]);
}
////////////////////////////////////////////////////////////////////////



 ////////////////////////////////////////////////////////////////////////
 const onDelete = () => {

    console.log('DELETE');

    alert('Please speak to supervisor to have this removed');

    // if(tableName === 'supportLog'){

    //     console.log('Support log delete function');

    //     Service.deleteOnDB('supportLog', selectedRows, tableData).then(data => {

    //         resetTable();
    //     })
        

    // }else if(tableName === 'monitoringTool'){

    //     Service.deleteOnDB('monitoringTool', selectedRows, tableData).then(data => {

    //         resetTable();
    //     })

    // }else if(tableName === 'contacts'){

    //     Service.deleteOnDB('contacts', selectedRows, tableData).then(data => {

    //         resetTable();
    //     })
           
        
        
    // }else if(tableName === 'supportGroup'){

    //     Service.deleteOnDB('supportGroup', selectedRows, tableData).then(data => {

    //         resetTable();
    //     })
        
    // }
    
 }
 ////////////////////////////////////////////////////////////////////////


 ////////////////////////////////////////////////////////////////////////
const onEdit = () => {

setRecordForEdit(true);

const tableItem = tableData.find(tableItem => tableItem.id === selectedRows[0]);

let url = Service.getEditUrl(tableName, tableItem);

navigate(url);

}
 ////////////////////////////////////////////////////////////////////////


  ////////////////////////////////////////////////////////////////////////
const onAddNew = () => {

let url = Service.getAddNewUrl(tableName, contactId);

navigate(url);

}
 ////////////////////////////////////////////////////////////////////////



 ////////////////////////////////////////////////////////////////////////
const fetchTableData = () => {

    setIsLoading(true);

    let i = 1; 

    const apiName = Service.getAPIName(tableName);

    if(tableName !== 'supportGroup'){

        Service.getTokenForAPI().then(idToken => {
            Axios.get(`${Service.devAPI}/api/get/${apiName}/${contactId}`, {headers: {authorization: idToken}}).then((data) => {
    
                data.data.forEach(row => {
                    row.id = i;
                    
                    i++;
                })

                console.log('TABLE DATA RECIEVED', data.data);
        
                setTableData(data.data);
                setIsLoading(false);
        
            }).catch((error) => {
                console.log("Error getting Table Data:", error);
            });
        })

    }else{

        Service.getTokenForAPI().then(idToken => {
            Axios.get(`${Service.devAPI}/api/get/${apiName}`, {headers: {authorization: idToken}}).then((data) => {
    
                console.log('fetch support group table', data)
                data.data.forEach(row => {
                    row.id = i;
                    i++;
                })
        
                setTableData(data.data);
                setIsLoading(false);
        
            }).catch((error) => {
                console.log("Error getting Table Data:", error);
            });
        })
    }

}

useEffect(() => {
    
    fetchTableData();

}, [])
 ////////////////////////////////////////////////////////////////////////


  return (
      <>
            <Box sx={{ my: 3 }}>
                <Controls.Button
                    text="Add New"
                    startIcon={<AddIcon />}
                    onClick={onAddNew}
                />
            </Box>
            {!isLoading && 
                        <DataTable 
                        tableData={tableData} 
                        fetchTableData={fetchTableData}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        setDisableEditBtn={setDisableEditBtn}
                        setDisableDeleteBtn={setDisableDeleteBtn}
                        columns={columns}
                    />
            }
            <Box sx={{ my: 3 }}>
                <Controls.Button
                    text={selectedRows.length > 1 ? "Delete All" : "Delete"}
                    color='error'
                    disabled={disableDeleteBtn}
                    onClick={() => {
                        setConfirmDialog({
                            isOpen: true,
                            title: 'Are you sure to delete this record?',
                            subTitle: "You can't undo this operation",
                            onConfirm: () => { onDelete() }
                        })
                    }}
                    sx={{ mr: 1 }}
                />
                <Controls.Button
                    text="Clear Selected"
                    disabled={disableDeleteBtn}
                    onClick={clearSelected}
                    sx={{ mr: 1 }}
                />
                <Controls.Button
                    text="View / Edit"
                    disabled={disableEditBtn}
                    onClick={onEdit}
                />
            </Box>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
      </>
  );
}













