import React from 'react'
import { Paper, Card, Typography, Button } from '@material-ui/core'
import Grid from '@mui/material/Grid';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fdfdff'
    },
    pageHeader:{
        padding:theme.spacing(4),
        display:'flex',
        marginBottom:theme.spacing(2)
    },
    pageIcon:{
        display:'inline-block',
        padding:theme.spacing(2),
        color:'#031A4A'
    },
    pageTitle:{
        paddingLeft:theme.spacing(4),
        '& .MuiTypography-subtitle2':{
            opacity:'0.6'
        }
    }
}))

export default function PageHeader(props) {

    const classes = useStyles();
    const { title, subTitle, icon } = props;
    return (
        <Paper>
            <Grid container sx={{ px: 4, py: 2, mb: 4 }}>
            <div className={classes.pageHeader}>
                <Card className={classes.pageIcon}>
                   <img src='https://www.pslcharity.org.uk/wp-content/uploads/2020/05/parent-support-link-logo2.png'></img>
                </Card>
                <div className={classes.pageTitle}>
                    <Typography
                        variant="h6"
                        component="div">
                        {title}</Typography>
                    <Typography
                        variant="subtitle2"
                        component="div">
                        {subTitle}</Typography>
                </div>
            </div>
            </Grid>
        </Paper>
    )
}
