import React, { useState, useEffect } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../controls/Controls";
import {seperateDateValues} from '../../services/employeeService';
import * as Service from "../../services/employeeService";
import DatePicker from '../controls/DatePicker2';
import Typography from '@mui/material/Typography';
import {useAuth} from '../../context/auth-context'; 

export default function LoggedBy(props) {
    const {currentUser, logout} = useAuth();
    const [formData, setFormValues] = useState(props.fieldValues);
    const [thisSectionFormData, setThisSectionFormData] = useState([]);
    const {formOptions, setExitDateChanged} = props;

    useEffect(() => {
        
        setThisSectionFormData({
            "Logged By" : formData['Logged By'],
            "Date Logged" : formData['Date Logged'],
            "Exit date" : formData['Exit date'],
            "Hear about PSL" : formData['Hear about PSL'],
            "GDPR" : formData['GDPR'],
            "ConsentToShare" : formData['ConsentToShare']
        })

    }, [])
    

    const [showExitCalender, setShowExitCalender] = useState(false);
    
    const handleInputChange = e => {

        const { name, value } = e.target

        setThisSectionFormData({
            ...thisSectionFormData,
            [name]: value
        })
        setFormValues({
            ...formData,
            [name]: value
        })
    }

    useEffect(() => {
        const date = seperateDateValues(new Date)
        setThisSectionFormData({
            ...thisSectionFormData,
            'Date Logged' : `${date.day}/${date.month}/${date.year}`
        })
        setFormValues({
            ...formData,
            'Date Logged' : `${date.day}/${date.month}/${date.year}`
        })
    }, [])

    useEffect(() => {

        props.onCompleteFormSection(thisSectionFormData)
    
    }, [thisSectionFormData])

   


    const onDateChange = (date) => {

       const formatDate = seperateDateValues(date);

       setThisSectionFormData({
        ...thisSectionFormData,
        'Exit date' : `${formatDate.day}/${formatDate.month}/${formatDate.year}`
        })
        setFormValues({
        ...formData,
        'Exit date' : `${formatDate.day}/${formatDate.month}/${formatDate.year}`
        })
    

        setExitDateChanged(true);
      
    }


    const handleExitUserBtn = () => {
        if(currentUser.email === 'rhona.hawkins@pslcharity.org.uk' || currentUser.email === 'admin@pslcharity.org.uk'){
            setShowExitCalender(!showExitCalender);
        }else{
            alert('Please speak to supervisor to exit client')
        }
    }


    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Controls.SelectObj
                        label="Logged By - FSW"
                        name="Logged By"
                        value={formData['Logged By']}
                        onChange={handleInputChange}
                        options={formOptions['object']}
                    />
                    <Controls.Input
                        label="Date Logged"
                        name="Date Logged"
                        value={formData['Date Logged']}
                        onChange={handleInputChange}
                        disabled
                    />
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <Controls.Input
                                label="Exit date"
                                name="Exit date"
                                value={formData['Exit date']}
                                onChange={handleInputChange}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6} className="exit-btn">
                            <Controls.Button
                                text="Exit User"
                                color="error"
                                onClick={() => {handleExitUserBtn()}}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 4 }} >
                            {
                                showExitCalender && 

                                <DatePicker label='Exit Date' onDateChange={onDateChange} />

                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Controls.Select
                        label="Hear about PSL"
                        name="Hear about PSL"
                        value={formData['Hear about PSL']}
                        onChange={handleInputChange}
                        options={formOptions['single']['hearAboutUs']}
                    />
                    <Controls.SelectFieldObj
                        label="GDPR"
                        name="GDPR"
                        value={formData['GDPR']}
                        onChange={handleInputChange}
                        options={[{'value': 'TRUE', 'text': 'Yes'}, {'value': 'FALSE', 'text': 'No'}]}
                    />
                    <Controls.SelectFieldObj
                        label="Consent To Share"
                        name="ConsentToShare"
                        value={formData['ConsentToShare']}
                        onChange={handleInputChange}
                        options={[{'value': 'TRUE', 'text': 'Yes'}, {'value': 'FALSE', 'text': 'No'}]}
                    />
                </Grid>
            </Grid>
    )
}
