import React, {useState, useEffect} from 'react'
import {  Typography, Paper } from '@material-ui/core';
import Box from '@mui/material/Box';
import PageHeader from "../components/PageHeader";
import Grid from '@mui/material/Grid';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import {Form} from '../components/useForm';
import { useParams } from "react-router-dom";
import MonitoringToolFields from '../components/MonitoringToolFieldGroups/MonitoringToolFields';
import * as Service from "../services/employeeService";
import Controls from "../components/controls/Controls";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import * as Axios from 'axios';
import { useSearchParams } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import ConfirmDialog from '../components/ConfirmDialog';
import Notification from '../components/Notification';





const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}))

const initalformData = {
    ...Service.initalformMonitoringTool
}



export default function MonitoringTool() {

    const classes = useStyles();
    const params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const monitorID = searchParams.get("MonitorID");
    const contactId = searchParams.get("contactId");
    const [isLoading, setIsLoading] = useState(false);
    const [monitoringTool, setMonitoringTool] = useState(initalformData)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })

    const navigate = useNavigate();
    const isEdit = searchParams.get("edit") === 'true' ? true : false;

    const fetchData = () => {

        setIsLoading(true);

        if(monitorID){

            Service.getTokenForAPI().then(idToken => {
                Axios.get(`${Service.devAPI}/api/get/monitoringtool/${monitorID}`, {headers: {authorization: idToken}}).then((data) => {

                    setMonitoringTool(data.data[0]);
    
                    console.log('Table Data has been fetched successfully', data.data);
    
                    setIsLoading(false);
    
        
                }).catch((error) => {
                    console.log("Error getting Contact Data:", error);
                    setIsLoading(false);
                });
            })
    
        }else{
            setIsLoading(false);
        }

    }

    useEffect(() => {
        
        fetchData();

    }, [])



    const handleSubmit = e => {

        console.log(monitoringTool);

        if(isEdit){

            let updateObj = {
                log: monitoringTool,
                apiName: 'monitoringtool',
                id: monitoringTool.MonitorID,
            }
        
            Service.updateRowOnDB(updateObj).then((data) => {
        
                console.log('support Log successfully updated', data);
            
                navigate(`/contact/${contactId}/?submit=success&type=Update`);
        
            }).catch((error) => {
    
                console.log('error updating support Log', error);
    
                navigate(`/contact/${contactId}/?submit=error&type=Update`);
    
            });
        }else{

            let subForm = Service.matchKeyObj(monitoringTool, 'monitoringtool');

            subForm['UniqueIdentifier'] = contactId;
            subForm['MonitorID'] = Service.generateUID();

            Service.submitToDB(subForm, 'monitoringtool').then((data) => {

                console.log('support Log successfully added', data);
        
                navigate(`/contact/${contactId}/?submit=success&type=Submit`);    
        
            }).catch((error) => {
        
                console.log('Adding Support Log Failed', error);
    
                navigate(`/contact/${contactId}/?submit=error&type=Submit`);    
        
            });

           console.log(subForm);

        }
    }


    const saveFormSectionData = (enteredFormSectionData) => {

        setMonitoringTool({
            ...monitoringTool,
            ...enteredFormSectionData,
        })
    }




    return (

        <>
                <Box sx={{ mb: 2}}>
                    <Controls.Button
                        text="Back"
                        component={Link} 
                        to={`/contact/${contactId}`}
                    />
                </Box>
            <PageHeader
                title='Monitoring Tool'
                icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
            />
 
            {!isLoading && 
                <Form>
                    <Paper >
                        <Grid container sx={{ p: 4, mb: 2}}>
                            <Typography variant="h5" sx={{ my: 4 }} component={'span'} >Questions</Typography>
                            <MonitoringToolFields fieldValues={monitoringTool} onCompleteFormSection={saveFormSectionData} />

                            <Controls.Button
                            sx={{ mt: 4}}
                            text={isEdit === true ? 'Update' : 'Submit'}
                            color="primary"
                            onClick={() => {
                                setConfirmDialog({
                                    isOpen: true,
                                    title: 'Are you sure to submit this record?',
                                    subTitle: "Make sure the information provided is correct",
                                    onConfirm: () => { handleSubmit() }
                                })
                            }}
                            />
                    
                        </Grid>
                    </Paper>
                </Form>
            }
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}
