import React, {useState, useEffect} from 'react'
import PageHeader from "../components/PageHeader";
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import { Paper } from '@material-ui/core';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as Service from "../services/employeeService";
import SimpleBarChart from '../components/Charts/SimpleBarChart';
import ReportGrid from '../components/Charts/ReportGrid.js';
import { makeStyles } from '@material-ui/styles';
import * as Axios from 'axios';
import SimpleLineChart from '../components/Charts/SimpleLineChart';
import { Typography } from '@material-ui/core'
import Alert from '@mui/material/Alert';
import PasswordProtector from '../components/password-protector';



export default function Reporting() {

    let rowCount = 0;

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: true, title: 'Password Protection', subTitle: '' })

    useEffect(() => {
       
        // Service.getRowCountOnDB('addressDetails').then(data => {
        //     rowCount = data[0]['COUNT(*)'];
        //     console.log('row count!', rowCount);
        // })

      }, []); 

    return (
        <>       

            <PageHeader
                title='Reporting'
                subTitle="View PSL Reporting Statistics"
                icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
            />








                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4, }}> 
                        <Grid item xs={12}>
                            <SimpleBarChart 
                                chartDataFunc={Service.getNewClientsBarChart} 

                                XAxisName='Local Hub' 
                                BarName='Number'
                                // location='Southampton'
                                heading="New clients by Local Hub (Comparison)" />

                        </Grid>
                    </Grid>
                </Paper>



                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4,}}> 
                        <Grid item xs={12}>
                
                            <SimpleBarChart 
                                chartDataFunc={Service.getMethodsOfSupportBarChart} 

                                selectOptions={Service.getHubsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel='Local Hub'
                                selectName='hubName'
                                defaultSelectOption="Soton"

                                XAxisName='Support Type' 
                                BarName='Methods of Support'
                                heading="Methods of Support by Local Hub (Comparison)" />

                        </Grid>
                    </Grid>
                </Paper>


                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4,}}> 
                        <Grid item xs={12}>
                
                            <SimpleBarChart 
                                chartDataFunc={Service.getWhoTheyAreBarchart} 

                                selectOptions={Service.getHubsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel='Local Hub'
                                selectName='hubName'
                                defaultSelectOption="Soton"

                                XAxisName='Carers Relationship to User' 
                                BarName='Number'
                                heading="Carers Relationship to User (Who they are) by local hub (Comparison)" />

                        </Grid>
                    </Grid>
                </Paper>




                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4, }}> 
                        <Grid item xs={12}>
                            <SimpleBarChart 
                                chartDataFunc={Service.getIssuesdentifiedreport} 

                                selectOptions={Service.getHubsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel='Local Hub'
                                selectName='hubName'
                                defaultSelectOption="Soton"

                                XAxisName='issue' 
                                BarName='Number'
                                // location='Southampton'
                                heading="Issues Identified at 1 to 1 (Comparison)" />

                        </Grid>
                    </Grid>
                </Paper>




                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4, }}> 
                        <Grid item xs={12}>
                            <SimpleBarChart 
                                chartDataFunc={Service.getRelatedToReport} 
                                // selectOptions={Service.getSupportLogFormFieldsFromDB}
                                // selectOptionsKey='location'

                                selectOptions={Service.getHubsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel='Local Hub'
                                selectName='hubName'
                                defaultSelectOption="Soton"

                                XAxisName='Drug' 
                                BarName='Number'
                                // location='Southampton'
                                heading="Related to Substance (Comparison)" />

                        </Grid>
                    </Grid>
                </Paper>


                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4, }}> 
                        <Grid item xs={12}>
                            <SimpleBarChart 
                                chartDataFunc={Service.getEthnicityReport} 
                                // selectOptions={Service.getSupportLogFormFieldsFromDB}
                                // selectOptionsKey='location'

                                selectOptions={Service.getHubsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel='Local Hub'
                                selectName='hubName'
                                defaultSelectOption="Soton"

                                XAxisName='Ethnicity' 
                                BarName='Number'
                                // location='Southampton'
                                heading="Ethnicity (Comparison)" />

                        </Grid>
                    </Grid>
                </Paper>










































{/* 
                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4}}> 
                        <Grid item xs={12}>
                
                            <SimpleLineChart 
                                chartDataFunc={Service.getNewClientsLineGraph2} 
                                selectOptions={Service.getLocationSelectOptionsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel='Location'
                                selectName='location'
                                defaultSelectOption="Southampton"
                                XAxisName='yearAndMonth' 
                                BarName='Count'
                                heading="New Clients (Trend)"
                                
                                 />

                        </Grid>
                    </Grid>
                </Paper> 
             */}

{/* 
                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4,}}> 
                        <Grid item xs={12}>
                
                            <SimpleBarChart 
                                chartDataFunc={Service.getMethodsOfSupportBarChart} 

                                selectOptions={Service.getLocationSelectOptionsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel='Location'
                                selectName='location'
                                defaultSelectOption="Southampton"

                                XAxisName='Support Type' 
                                BarName='Methods of Support'
                                heading="Methods of Support (Comparison)" />

                        </Grid>
                    </Grid>
                </Paper>

                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4,}}> 
                        <Grid item xs={12}>
                
                            <SimpleLineChart 
                                chartDataFunc={Service.getMethodsOfSupportLineGraph} 

                                selectOptions={Service.getSupportLogFormFieldsFromDB}
                                selectOptionsKey='supportTypes'
                                selectOptionsLabel="Support Type"
                                selectName="Support Type"
                                defaultSelectOption="Telephone (Incoming)"

                                selectOptions2={Service.getLocationSelectOptionsFromDB}
                                selectOptionsKey2={false}
                                selectOptionsLabel2='Location'
                                selectName2='location'
                                defaultSelectOption2="Southampton"


                                XAxisName='yearAndMonth' 
                                BarName='Count'
                                location='Southampton'
                                heading="Methods of Support (Trend)"
                                defaultSelectOption="Telephone (Incoming)"
                                 />

                        </Grid>
                    </Grid>
                </Paper> 

                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4,}}> 
                        <Grid item xs={12}>
                
                            <SimpleLineChart 
                                chartDataFunc={Service.getRelatedToLineGraph} 

                                selectOptions={Service.getDrugsSelectOptionsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel="Drug"
                                selectName="drugs"
                                defaultSelectOption="Alcohol"

                                selectOptions2={Service.getLocationSelectOptionsFromDB}
                                selectOptionsKey2={false}
                                selectOptionsLabel2='Location'
                                selectName2='location'
                                defaultSelectOption2="Southampton"


                                XAxisName='yearAndMonth' 
                                BarName='Count'
                                location='Southampton'
                                heading="Related to Substance (Trend)"
                                 />

                        </Grid>
                    </Grid>
                </Paper> 

         

                  <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4,}}> 
                        <Grid item xs={12}>
                
                            <SimpleLineChart 
                                chartDataFunc={Service.getIssuesdentifiedLineGraph} 

                                selectOptions={Service.getIssues}
                                selectOptionsKey={false}
                                selectOptionsLabel="Issue"
                                selectName="issue"
                                defaultSelectOption="User In Treatment?"

                                selectOptions2={Service.getLocationSelectOptionsFromDB}
                                selectOptionsKey2={false}
                                selectOptionsLabel2='Location'
                                selectName2='location'
                                defaultSelectOption2="Southampton"


                                XAxisName='yearAndMonth' 
                                BarName='Count'
                                location='Southampton'
                                heading="Issues Identified at 1 to 1 (Trend)"
                                 />

                        </Grid>
                    </Grid>
                </Paper> 

                 <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4, }}> 
                        <Grid item xs={12}>
                            <SimpleBarChart 
                                chartDataFunc={Service.getHearAboutUsReport} 

                                selectOptions={Service.getLocationSelectOptionsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel='Location'
                                selectName='location'
                                defaultSelectOption="Southampton"

                                XAxisName='Hear about PSL' 
                                BarName='Number'
                                // location='Southampton'
                                heading="How Did You Hear About PSL? (Comparison)" />

                        </Grid>
                    </Grid>
                </Paper>

                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4,}}> 
                        <Grid item xs={12}>
                
                            <SimpleLineChart 
                                chartDataFunc={Service.getHearAboutUsLineGraph} 

                                selectOptions={Service.getHearAboutUsSelectOptionsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel="Hear About Us"
                                selectName="hearAboutUs"
                                defaultSelectOption="Southampton Alcohol Services"

                                selectOptions2={Service.getLocationSelectOptionsFromDB}
                                selectOptionsKey2={false}
                                selectOptionsLabel2='Location'
                                selectName2='location'
                                defaultSelectOption2="Southampton"


                                XAxisName='yearAndMonth' 
                                BarName='Count'
                                location='Southampton'
                                heading="How Did You Hear About PSL? (Trend)"
                                 />

                        </Grid>
                    </Grid>
                </Paper> 


                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4, }}> 
                        <Grid item xs={12}>
                            <SimpleBarChart 
                                chartDataFunc={Service.getLocalHubBarChart} 

                                selectOptions={Service.getLocationSelectOptionsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel='Location'
                                selectName='location'
                                defaultSelectOption="Southampton"

                                XAxisName='Local Hub' 
                                BarName='Number'
                                // location='Southampton'
                                heading="New Contacts - Local Hubs (Comparison)" />

                        </Grid>
                    </Grid>
                </Paper>

                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4,}}> 
                        <Grid item xs={12}>
                
                            <SimpleLineChart 
                                chartDataFunc={Service.getLocalHubLineGraph} 

                                selectOptions={Service.getHubsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel="Local Hubs"
                                selectName="localhubs"
                                defaultSelectOption="Soton"

                                selectOptions2={Service.getLocationSelectOptionsFromDB}
                                selectOptionsKey2={false}
                                selectOptionsLabel2='Location'
                                selectName2='location'
                                defaultSelectOption2="Southampton"


                                XAxisName='yearAndMonth' 
                                BarName='Count'
                                location='Southampton'
                                heading="New Contacts - Local Hubs (Trend)"
                                 />

                        </Grid>
                    </Grid>
                </Paper> 


                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4, }}> 
                        <Grid item xs={12}>
                            <SimpleBarChart 
                                chartDataFunc={Service.getSupportGroupsBarChart} 

                                selectOptions={Service.getLocationSelectOptionsFromDB}
                                selectOptionsKey={false}
                                selectOptionsLabel='Location'
                                selectName='location'
                                defaultSelectOption="Southampton"

                                XAxisName='Work Shop' 
                                BarName='Number'
                                // location='Southampton'
                                heading="Support Groups - (Comparison) v2" />

                        </Grid>
                    </Grid>
                </Paper> */}



                <Paper className="mb-4">
                    <Grid container spacing={2} sx={{ p: 4, }}> 
                        <Grid item xs={12}>

                            <ReportGrid />

                        </Grid>
                    </Grid>
                </Paper>


                <PasswordProtector
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                />
                
        </>
    )
}

